<template>
    <v-container
        fluid
        class="pa-0 lightGray"
    >
        <dtv-header />
        <v-row
            justify="space-around"
            class="mt-16"
            no-gutters
        >
            <v-col
                cols="12"
                sm="10"
                md="8"
                class="mt-16"
            >

                <div class="text-center dtv-title">
                    {{ $route.meta.title }}
                </div>
                <div
                    class="text-center mt-4 text-h6"
                    v-if="!result"
                >
                    To find out what your local regional sports networks are and which pro
                    teams can be viewed on those channels please enter your ZIP Code.
                </div>
            </v-col>
        </v-row>
        <h5
            v-if="error"
            class="red--text text-center mt-4"
        >
            {{ error }}
        </h5>
        <v-row
            v-if="result"
            justify="space-around"
            class="mt-3"
        >
            <v-col
                cols="12"
                sm="10"
                md="8"
                lg="7"
                xl="6"
            >
                <div class="text-center mt-4 text-h6">
                    The {{ zip }} ZIP Code is in the home team territory for the following
                    pro teams. With HD Access select channels and teams available in HD
                    are noted below:
                </div>
            </v-col>
        </v-row>
        <v-row
            justify="space-around"
            class="mt-5"
            v-if="!result"
        >
            <v-col
                :cols="$vuetify.breakpoint.width < 350 ? 12 : 10"
                sm="5"
                md="4"
                lg="3"
            >
                <v-text-field
                    v-mask="mask"
                    :rules="rules"
                    :loading="loading"
                    solo
                    class="rounded-pill"
                    color="black"
                    label="ZIP Code"
                    append-icon="fas fa-search"
                    hide-details="auto"
                    v-model="zip"
                    @click:append="getInfo"
                    @change="getInfo"
                    @input="rate = null"
                >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row
            v-if="result"
            justify="center"
            no-gutters
        >
            <v-col
                cols="12"
                md="10"
            >
                <v-row
                    no-gutters
                    justify="center"
                >
                    <v-col
                        cols="12"
                        v-if="bulletins"
                        class="d-print-none mt-5"
                    >
                        <v-card
                            v-for="bulletin in bulletins"
                            :key="bulletin.id"
                        >
                            <v-card-text v-html="bulletin.body" />
                        </v-card>
                    </v-col>
                </v-row>
                <v-row
                    no-gutters
                    justify="center"
                >
                    <template v-if="!isCommercial">
                        <rsn-channel
                            :channel="channel"
                            v-for="channel in result"
                            :key="'rc_' + channel.number_major + channel.number_minor"
                        />
                    </template>
                    <template v-else>
                        <v-col>
                            <!-- Header -->
                            <v-row
                                class="mt-2 mx-1 mx-lg-0 font-weight-bold"
                                no-gutters
                                v-if="$vuetify.breakpoint.lgAndUp"
                            >
                                <v-col
                                    cols="12"
                                    lg="3"
                                    class="text-center"
                                >
                                    <v-card
                                        tile
                                        outlined
                                        class="network-header"
                                    >
                                        Network
                                    </v-card>
                                </v-col>
                                <v-col
                                    cols="5"
                                    lg="3"
                                    class="text-center"
                                >
                                    <v-card
                                        tile
                                        outlined
                                        class="packages-header"
                                    >
                                        Packages
                                    </v-card>
                                </v-col>
                                <v-col
                                    lg="6"
                                    class="text-center"
                                >
                                    <v-card
                                        tile
                                        outlined
                                        class="teams-header"
                                    >
                                        Affiliate/Teams
                                    </v-card>
                                </v-col>
                            </v-row>

                            <!-- Channel blocks -->
                            <v-card
                                v-for="(channel, index) in allChannels"
                                :key="'channel_' + channel.number_major + channel.number_minor"
                                class="mb-2 mx-1 mx-md-0"
                                outlined
                            >
                                <v-row
                                    class="font-weight-bold"
                                    no-gutters
                                    v-if="$vuetify.breakpoint.mdAndDown"
                                >
                                    <v-col
                                        cols="12"
                                        sm="7"
                                        lg="3"
                                        class="text-center"
                                    >
                                        <v-card
                                            tile
                                            outlined
                                            class="network-header"
                                        >
                                            Network
                                        </v-card>
                                    </v-col>
                                    <v-col
                                        v-if="$vuetify.breakpoint.smAndUp"
                                        cols="5"
                                        lg="2"
                                        class="text-center"
                                    >
                                        <v-card
                                            tile
                                            outlined
                                            class="packages-header"
                                        >
                                            Packages
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <!-- Channel information -->
                                <v-row
                                    class="py-4"
                                    no-gutters
                                >
                                    <v-col
                                        cols="6"
                                        sm="2"
                                        lg="1"
                                        class="text-center"
                                    >
                                        <rsn-channel-logo
                                            :channel="channel"
                                            class="mx-1"
                                        />
                                    </v-col>

                                    <v-col
                                        cols="6"
                                        sm="5"
                                        lg="2"
                                        class="px-1"
                                    >
                                        <h3 class="jet">{{ channel.title_display }}</h3>
                                        <div class="jet">{{ channel.callsign_display }}</div>
                                        <div class="jet">Channel {{ channel.number_display }}</div>
                                    </v-col>

                                    <v-col
                                        cols="12"
                                        sm="5"
                                        lg="3"
                                        class="px-1 pb-2"
                                    >
                                        <template v-if="channel.has_matching_rsn">
                                            <div class="jet font-weight-bold mb-2 text-center mt-3 mt-sm-0">
                                                {{ $vuetify.breakpoint.xsOnly ? 'Packages on' : '' }}
                                                Satellite{{ channel.rsn_channels[0].stream ? ` and
                                                Stream` : '' }}
                                            </div>
                                            <!-- <div class="jet">Commercial Packages:</div> -->
                                            <div class="package-list mt-1 text-center">
                                                <div
                                                    v-if="channel.rsn_channels[0].bxp || channel.rsn_channels[0].choice || channel.rsn_channels[0].sports_pack || channel.rsn_channels[0].ultimate"
                                                    class="package bxtra text-lg-h6"
                                                >
                                                    Business XTRA Pack
                                                </div>
                                                <div
                                                    v-if="channel.rsn_channels[0].choice || channel.rsn_channels[0].ultimate"
                                                    class="package cchoicep text-lg-h6"
                                                >
                                                    Commercial Choice Plus
                                                </div>
                                                <div
                                                    v-if="channel.rsn_channels[0].cxp || channel.rsn_channels[0].choice || channel.rsn_channels[0].sports_pack || channel.rsn_channels[0].ultimate"
                                                    class="package cxtrap text-lg-h6"
                                                >
                                                    Commercial Xtra Pack
                                                </div>
                                                <div
                                                    v-if="channel.rsn_channels[0].ultimate"
                                                    class="package cultimate text-lg-h6"
                                                >
                                                    Commercial Ultimate
                                                </div>
                                            </div>

                                        </template>
                                        <div
                                            v-else
                                            class="mt-5 text-center"
                                        >
                                            Available for purchase <br /> as an a-la-carte option.
                                        </div>
                                    </v-col>

                                    <!-- Teams section -->
                                    <template v-if="channel.rsn_channels[0].teams.length">
                                        <!-- <v-col
                                            cols="12"
                                            v-if="$vuetify.breakpoint.mdAndDown"
                                        >
                                            <v-card
                                                tile
                                                outlined
                                                class="teams-header text-center"
                                            >
                                                Affiliate/Teams
                                            </v-card>
                                        </v-col> -->
                                        <v-col
                                            cols="12"
                                            lg="6"
                                        >
                                            <v-row
                                                justify="center"
                                                class="mt-1"
                                            >
                                                <v-col
                                                    v-for="team in displayedTeams(channel)"
                                                    :key="team.id"
                                                    :cols="teamCols"
                                                    class="text-center"
                                                >

                                                    <team :team="team" />
                                                </v-col>
                                            </v-row>
                                            <v-row
                                                v-if="hasMoreTeams(channel)"
                                                justify="center"
                                                class="mt-2"
                                            >
                                                <v-col
                                                    cols="12"
                                                    class="text-center"
                                                >
                                                    <v-btn
                                                        variant="outlined"
                                                        @click="expandedTeams.push(channel.number_major)"
                                                        size="small"
                                                    >
                                                        Show All Teams ({{ channel.rsn_channels[0].teams.length }})
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>



                                    </template>
                                </v-row>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-col>
        </v-row>
        <v-row
            class="mb-10"
            style="height:200px"
            justify="center"
        >
            <v-col cols="auto" class="text-center">
                <a
                    href="https://www.directv.com/packages/"
                    target="_blank"
                    class="not-a-customer-link"
                >Not a DIRECTV customer?
                    Learn more about our packages.</a>
            </v-col>
        </v-row>
        <dtv-footer />
    </v-container>
</template>


<script>
import { mask } from "vue-the-mask";
import ApiService from "../api/api.service";
import RsnChannel from "../components/Rsinfo/RsnChannel";
import RsnChannelLogo from "../components/Rsinfo/RsnChannelLogo";
import Team from "../components/Home/Games/Game/Teams/Team";

import DtvHeader from "../components/shared/DtvHeader";
import DtvFooter from "../components/shared/DtvFooter";


export default {
    name: "Rsinfo",
    directives: {
        mask,
    },
    components: {
        RsnChannel,
        RsnChannelLogo,
        DtvHeader,
        DtvFooter,
        Team,
    },
    data() {
        return {
            loading: false,
            zip: "",
            error: null,
            result: null,
            mask: "#####-####",
            rules: [
                (v) =>
                    /^0|[0-9]{5}(?:-[0-9]{4})?|null$/.test(v) ||
                    "Please enter a valid ZIP Code",
            ],
            expandedTeams: [],
        };
    },
    computed: {
        teamCols() {
            let cols = 4;
            if (this.$vuetify.breakpoint.width < 400) {
                cols = 6;
            }
            else if (this.$vuetify.breakpoint.smAndUp) {
                cols = 3;
            }
            else if (this.$vuetify.breakpoint.mdAndUp) {
                cols = 3;
            }
            else if (this.$vuetify.breakpoint.lgAndUp) {
                cols = 4;
            }
            else if (this.$vuetify.breakpoint.xl) {
                cols = 3;
            }
            return cols;
        },
        teamsInRow() {
            return 12 / this.teamCols;
        },
        isCommercial() {
            return this.$route.name === "local-info-commercial";
        },
        allChannels() {
            return this.result ? this.result : [];
        },
        flattenedRows() {
            return this.allChannels.flatMap(channel => {
                const rows = [
                    { type: 'channel', channel, key: 'rcc_' + channel.number_major + channel.number_minor }
                ];

                if (channel.has_matching_rsn) {
                    rows.push({
                        type: 'teams',
                        channel,
                        key: 'rcc_team_' + channel.number_major + channel.number_minor
                    });
                }

                return rows;
            });
        }
    },
    methods: {
        getInfo() {
            this.error = null;
            this.result = null;
            this.bulletin = null;
            this.loading = true;
            ApiService.post("/rsinfo", {
                zip: this.zip,
                commercial: this.isCommercial,
            })
                .then(({ data }) => {
                    if (data.message && data.message.length > 0) {
                        this.error = data.message;
                    }
                    this.result = data.channels;
                    this.bulletins = data.bulletins;
                    this.loading = false;
                })
                .catch(({ response }) => {
                    this.error = response.message;
                    this.loading = false;
                });
            return;
        },
        reset() {
            this.error = null;
            this.result = null;
        },
        displayedTeams(channel) {
            if (this.expandedTeams.includes(channel.number_major)) {
                return channel.rsn_channels[0].teams;
            }
            return channel.rsn_channels[0].teams.slice(0, this.teamsInRow).sort(() => 0.5 - Math.random());;
        },
        hasMoreTeams(channel) {
            if (this.expandedTeams.includes(channel.number_major)) {
                return false;
            }
            return channel.rsn_channels[0].teams.length > this.teamsInRow;
        },
    },
    mounted() {
        // this.getInfo();
    },
};
</script>

<style scoped>
.mt-17 {
    margin-top: 68px;
}

.dtv-title {
    font-size: 1.5em;
    font-weight: bold;
}

.v-text-field>>>.v-label {
    font-size: 1.5em;
}

.v-text-field>>>input {
    font-size: 1.5em;
    padding: 40px 30px;
    max-height: 85px;
}

.package-list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}

.package-list li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 4px;
    font-size: 14px;
}

.package-list li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #2e71bc;
    font-weight: bold;
}

.jet {
    color: #102641;
}

.network-header {
    font-size: 1.5em;
    color: white;
    background-color: #102641;
}

.packages-header {
    font-size: 1.5em;
    background-color: #F5F5F5;

}

.teams-header {
    font-size: 1.5em;
    background-color: #EAF1FA;
}

.package {
    text-decoration: underline rgba(25, 118, 210, 0.4)
}

.bextra {
    color: rgb(121, 38, 123);
}

.cchoicep {
    color: rgb(232, 133, 51);
}

.cxtrap {
    color: rgb(121, 38, 123);
}

.cultimate {
    color: rgb(15, 39, 65);
}
</style>
