<template>
    <div>
        <loading v-if="loading" />
        <template v-else>
            <!-- Added H2 for main page title -->
            <h2 class="mb-4">League Coaches</h2>
            <!-- Conferences and Divisions -->
            <v-row>
                <v-col
                    v-for="conference in sortedLeague.season.conferences"
                    :key="'conf_' + conference.conferenceId"
                    cols="12"
                    md="6"
                >
                    <!-- Wrapped conference name with H3 -->
                    <v-card-title>
                        <h3>{{ conference.name }}</h3>
                    </v-card-title>
                    <v-row>
                        <v-col
                            v-for="division in conference.divisions"
                            :key="'div_' + division.divisionId"
                            cols="12"
                            sm="6"
                        >
                            <v-card>
                                <!-- Wrapped division name with H4 -->
                                <v-card-title>
                                    <h4>{{ division.name }}</h4>
                                </v-card-title>
                                <v-card-text>
                                    <!-- Teams and Coaches in Each Division -->
                                    <v-list>
                                        <v-list-item
                                            v-for="team in division.teams"
                                            :key="'team_' + team.teamId"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ team.location }}
                                                    {{ team.nickname }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Coaches:
                                                </v-list-item-subtitle>
                                                <v-list>
                                                    <v-list-item
                                                        v-for="coach in processCoaches(
                                                            team.coaches
                                                        )"
                                                        :key="
                                                            'coach_' +
                                                            coach.coachId
                                                        "
                                                    >
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{
                                                                    coach.firstName
                                                                }}
                                                                {{
                                                                    coach.lastName
                                                                }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle
                                                                v-for="position in coach.positions"
                                                                :key="
                                                                    position.coachPositionId
                                                                "
                                                            >
                                                                {{
                                                                    position.name
                                                                }}
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import { GET_LEAGUE_COACHES } from "../../store/actions.type";
import Loading from "../../components/shared/Loading";

export default {
    name: "Coaches",
    components: {
        Loading,
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        statsData() {
            return this.$store.getters.currentStatsData;
        },
        league() {
            return this.statsData.league;
        },
        sortedLeague() {
            if (
                !this.league ||
                !this.league.season ||
                !this.league.season.conferences
            )
                return this.league;
            const sortedConferences = [...this.league.season.conferences]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((conference) => {
                    const sortedDivisions = [...conference.divisions]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((division) => {
                            const sortedTeams = [...division.teams].sort(
                                (a, b) => {
                                    // Sorting teams by a combination of location and nickname
                                    const aName = (
                                        a.location + a.nickname
                                    ).toLowerCase();
                                    const bName = (
                                        b.location + b.nickname
                                    ).toLowerCase();
                                    return aName.localeCompare(bName);
                                }
                            );
                            return { ...division, teams: sortedTeams };
                        });
                    return { ...conference, divisions: sortedDivisions };
                });
            return {
                ...this.league,
                season: {
                    ...this.league.season,
                    conferences: sortedConferences,
                },
            };
        },
    },
    methods: {
        positionsString(positions) {
            return positions.map((p) => p.abbreviation).join(", ");
        },
        processCoaches(coaches) {
            //Some teams have the same coach twice, but for different positions. Loop through the coaches and combine the positions into an array under the same coach.
            var processedCoaches = [];
            coaches.forEach((coach) => {
                var existingCoach = processedCoaches.find(
                    (c) => c.coachId == coach.coachId
                );
                if (existingCoach) {
                    existingCoach.positions.push(coach.coachPosition);
                } else {
                    coach.positions = [coach.coachPosition];
                    processedCoaches.push(coach);
                }
            });
            return processedCoaches;
        },
    },
    mounted() {
        this.$store
            .dispatch(GET_LEAGUE_COACHES, this.$route.params.league)
            .then(() => {
                this.loading = false;
            });
    },
};
</script>
