<template>
    <div style="overflow: hidden" v-dragscroll.x>
        <v-row class="d-none d-print-block">
            <v-col class="text-center black--text">
                <div class="printed-date">{{ printHeaderDate }} | DIRECTV</div>
            </v-col>
        </v-row>
        <v-row justify="center" no-gutters>
            <v-col cols="auto">
                <!-- MOBILE -->
                <v-row
                    v-if="isMobile"
                    no-gutters
                    justify="space-around"
                    class="d-print-none"
                >
                    <v-col
                        cols="auto"
                        class="text-center action-link"
                        @click="changeMonth(selectable_option_index)"
                        v-for="(
                            selectable_option, selectable_option_index
                        ) in option.options"
                        :key="selectable_option.name"
                    >
                        <v-hover class="ma-auto" v-slot:default="{ hover }">
                            <v-card
                                elevation="0"
                                flat
                                height="42"
                                min-width="42"
                                class="d-flex pa-0 mx-1"
                            >
                                <v-card-text
                                    class="align-self-center pa-0 ma-1 ma-sm-2"
                                    :class="
                                        barTextClass(
                                            selectable_option_index,
                                            hover
                                        )
                                    "
                                >
                                    <div class="day text-h6 text-sm-h5">
                                        {{ selectable_option.text }}
                                    </div>
                                    <div class="date">
                                        {{ selectable_option.subtext }}
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
                <!-- DESKTOP -->
                <v-row v-else dense class="d-print-none mt-4 date-row">
                    <slot name="prepend" />
                    <v-col
                        class="text-center action-link"
                        @click="changeMonth(selectable_option_index)"
                        v-for="(
                            selectable_option, selectable_option_index
                        ) in option.options"
                        :key="selectable_option.name"
                    >
                        <v-hover class="ma-auto" v-slot:default="{ hover }">
                            <v-card
                                :elevation="hover ? 6 : 0"
                                flat
                                tile
                                min-height="75"
                                max-height="100"
                                min-width="100"
                                class="d-flex pa-0"
                            >
                                <v-card-text
                                    class="align-self-center pa-2 pa-lg-4"
                                    :class="
                                        barTextClass(
                                            selectable_option_index,
                                            hover
                                        )
                                    "
                                >
                                    <div
                                        class="font-weight-bold"
                                        :class="
                                            $vuetify.breakpoint.lgAndUp
                                                ? 'day-lg'
                                                : 'day-md'
                                        "
                                    >
                                        {{ selectable_option.text }}
                                    </div>
                                    <div class="date-md pt-2">
                                        {{ selectable_option.subtext }}
                                    </div>
                                </v-card-text>
                                <v-divider
                                    v-if="
                                        selectable_option_index !=
                                        option.options.length - 1
                                    "
                                    vertical
                                    color="lightGrey"
                                    class="date-divider ma-auto"
                                />
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "MonthBar",
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        option() {
            return this.$store.getters.getSingleOption("months");
        },
        printHeaderDate() {
            if (this.$route.params.month && this.$route.params.year) {
                const date = new Date();
                date.setMonth(this.$route.params.month - 1);

                const monthText = date
                    .toLocaleString("en-US", {
                        month: "long",
                    })
                    .toLowerCase();
                return monthText + " " + this.$route.params.year;
            } else {
                var d = new Date();
                var n = d.getMonth();
                var y = d.getFullYear();
                const date = new Date();
                date.setMonth(n);

                const monthText = date
                    .toLocaleString("en-US", {
                        month: "long",
                    })
                    .toLowerCase();
                return monthText + " " + y;
            }
            return false;
        },
    },
    methods: {
        monthActive(index) {
            if (this.$route.params.month && this.$route.params.year) {
                return (
                    this.option.options[index].month ==
                        this.$route.params.month &&
                    this.option.options[index].year == this.$route.params.year
                );
            } else {
                var d = new Date();
                var n = d.getMonth();
                if (this.option.options[index].month == n + 1) {
                    return true;
                }
            }
            return false;
        },
        changeMonth(index) {
            if (this.$route.params.team && this.$route.params.team !== "0") {
                window.open(
                    `${
                        this.appUrl
                    }/leagues/${this.$route.params.league.toLowerCase()}/teams/${this.$route.params.team.toLowerCase()}/schedule/${
                        this.option.options[index].year
                    }/${this.option.options[index].month}`,
                    "_self"
                );
            } else {
                window.open(
                    `${
                        this.appUrl
                    }/leagues/${this.$route.params.league.toLowerCase()}/schedule/${
                        this.option.options[index].year
                    }/${this.option.options[index].month}`,
                    "_self"
                );
            }
        },
        printDate(date) {
            return moment(date).format("dddd, MMMM Do YYYY");
        },

        barTextClass(index, hover) {
            let text_class = "";

            text_class = this.monthActive(index)
                ? "blue--text"
                : "darkGray--text text--lighten-1";

            if (hover) {
                //text_class = text_class + " subtitle-1";
            }

            return text_class;
        },
    },
};
</script>

<style scoped>
.day {
    white-space: nowrap;
}
.date {
    font-size: 0.8em !important;
    white-space: nowrap;
}
.printed-date {
    font-size: 1.5em;
}
.day-lg {
    font-size: 1.8em !important;
}
.day-md {
    font-size: 1.6em !important;
}
.date-md {
    font-size: 1.2em !important;
}
.date-row {
    background-color: white;
    border: 8px solid var(--directv-light-darkGray);
}
.scroll-row {
    overflow: hidden;
}
.date-divider {
    height: 28px;
    border-width: 1px;
}
</style>
