var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lightGray",class:_vm.isMobile ? ' fill-height' : ''},[(!_vm.isFramed)?_c('dtv-header',{staticClass:"d-print-none"}):_vm._e(),_vm._v(" "),_c('v-main',{staticClass:"no-print-padding"},[_c('v-container',{staticClass:"px-2 px-sm-4 px-lg-8 px-xl-12",attrs:{"fluid":_vm.$vuetify.breakpoint.lgAndDown}},[(!_vm.isMobile)?_c('v-col',{staticClass:"text-center mt-4"},[_c('h1',{staticClass:"sport-header font-weight-bold"},[_vm._v("\n                    "+_vm._s(_vm.leagueNameDisplay)+"\n                    "),(_vm.hasTeam)?[_vm._v("\n                        - "+_vm._s(_vm.teamNameDisplay.toUpperCase())+"\n                    ")]:_vm._e(),_vm._v("\n                    "+_vm._s(_vm.$route.meta.title)+"\n                ")],2)]):_vm._e(),_vm._v(" "),(_vm.isMobile)?_c('v-row',{attrs:{"justify":"center","align":"center"}},[(!_vm.isNfl)?_c('v-col',{attrs:{"cols":"auto"}},[_c('a',{attrs:{"href":`${_vm.appUrl}/leagues/${_vm.$route.params.league.toLowerCase()}/schedule`}},[_c('v-img',{staticClass:"contain-paint",attrs:{"contain":"","src":`${_vm.appUrl}/img/logos/leagues/${_vm.$route.params.league.toLowerCase()}.svg`,"width":"80","height":"80","alt":_vm.toTitleCase(
                                    _vm.$route.params.league.toTitleCase
                                ) + ' Logo'}})],1)]):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[_c('h1',{staticClass:"text-center"},[_vm._v("\n                        "+_vm._s(_vm.leagueNameDisplay)+"\n                        "),(
                                _vm.$route.params.team &&
                                _vm.$route.params.team !== '0'
                            )?[_vm._v("\n                            - "+_vm._s(_vm.teamNameDisplay.toUpperCase())+"\n                        ")]:_vm._e(),_vm._v("\n                        "+_vm._s(_vm.$route.meta.title)+"\n                    ")],2)])],1):_vm._e(),_vm._v(" "),_c('v-row',{staticClass:"d-print-none",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('breadcrumb')],1)],1),_vm._v(" "),_c('v-row',{staticClass:"d-print-none",class:!_vm.isMobile ? 'my-4' : '',attrs:{"no-gutters":"","align":"center","justify":"center"}},[(!_vm.isNfl)?_c('v-col',{attrs:{"cols":"auto"}},[_c('a',{attrs:{"href":`${_vm.appUrl}/leagues/${_vm.$route.params.league.toLowerCase()}/schedule`}},[(!_vm.isMobile)?_c('v-img',{staticClass:"contain-paint",attrs:{"contain":"","src":`${_vm.appUrl}/img/logos/leagues/${_vm.$route.params.league.toLowerCase()}.svg`,"width":"80","height":"80","alt":_vm.toTitleCase(
                                    _vm.$route.params.league.toTitleCase
                                ) + ' Logo'}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tabs',{staticClass:"mx-sm-2",attrs:{"centered":"","background-color":"transparent","color":"grey darken-1","show-arrows":""}},_vm._l((_vm.filteredLinks),function(link){return _c('v-tab',{key:link.title,staticClass:"font-weight-bold",attrs:{"to":_vm.detailLink(link)}},[_vm._v("\n                            "+_vm._s(link.title)+"\n                        ")])}),1)],1)],1),_vm._v(" "),_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }