var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.games),function(game,game_index){return _c('v-row',{key:game.id,staticClass:"d-print-none mt-3",attrs:{"no-gutters":""}},[_c('v-col',[(_vm.showListHeader(game_index, false))?_c('v-row',{staticClass:"dense-header mx-md-3 my-5 pa-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"group-header-text"},[_vm._v("\n                        "+_vm._s(_vm.titleDisplay(game))+"\n                    ")])])],1):_vm._e(),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{staticClass:"mx-md-3 mb-5 py-3",class:_vm.isEmbed ? '' : 'dense-border',style:(_vm.isEmbed
                                ? `border: 4px solid #${_vm.embed.customization.primary};border-radius:4px;`
                                : ''),attrs:{"no-gutters":"","align":"center"}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":_vm.isMvp || _vm.layout == 2 ? 8 : false,"lg":_vm.isMvp ? 6 : false}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[(!_vm.isEmbed && _vm.layout == 1)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-avatar',{staticClass:"ml-2",attrs:{"color":game.thuuz_game
                                                        ? _vm.thuuzColor(
                                                              game
                                                                  .thuuz_game
                                                                  .gex_default
                                                          )
                                                        : 'white',"size":"10"},on:{"click":function($event){_vm.hypeDialogOpen = true}}})],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[(game.league.has_logo)?_c('v-card',{style:(_vm.isEmbed
                                                        ? 'background-color: #FCFCFC;'
                                                        : ''),attrs:{"flat":"","tile":"","href":!_vm.isEmbed &&
                                                    game.league
                                                        .show_season_schedule
                                                        ? `${_vm.appUrl}/leagues/${game.league.slug.toLowerCase()}/schedule`
                                                        : ''}},[_c('v-img',{staticClass:"ml-3 contain-paint",attrs:{"contain":"","src":`${_vm.appUrl}/img/logos/leagues/${game.league.slug}.svg`,"width":_vm.$vuetify.breakpoint
                                                            .xs
                                                            ? 30
                                                            : 50,"height":_vm.$vuetify.breakpoint
                                                            .xs
                                                            ? 30
                                                            : 50,"alt":game.league.title +
                                                        ' Logo'}})],1):_c('sport-badge-dense',{staticClass:"ml-3",attrs:{"game":game}})],1),_vm._v(" "),_c('v-col',{staticClass:"ml-2 text-center",staticStyle:{"white-space":"nowrap"},attrs:{"cols":"2","sm":"2"}},[_c('time-display',{attrs:{"game":game}})],1),_vm._v(" "),_c('v-col',[(game.description)?_c('div',{staticClass:"mx-2 mx-sm-5 text-center text-description"},[(
                                                        _vm.$vuetify.breakpoint
                                                            .smAndUp
                                                    )?_c('div',{staticClass:"text-sm-description"},[_vm._v("\n                                                    "+_vm._s(game.description)+"\n                                                ")]):_c('div',{staticClass:"text-description"},[_vm._v("\n                                                    "+_vm._s(game.description)+"\n                                                ")])]):_c('div',[_c('teams',{attrs:{"game":game}})],1)]),_vm._v(" "),(!_vm.isMvp && _vm.layout != 2)?_c('v-col',{attrs:{"cols":"auto","sm":"3","md":"6"}},[(
                                                    _vm.$vuetify.breakpoint
                                                        .smAndUp &&
                                                    !!game.thuuz_game
                                                )?_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[(
                                                        _vm.$vuetify.breakpoint
                                                            .mdAndUp
                                                    )?_c('v-col',{staticClass:"px-3"},[_vm._v("\n                                                    "+_vm._s(game.thuuz_game
                                                            .teaser_default)+"\n                                                ")]):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[_c('compact-win-probability',{attrs:{"game":game}})],1)],1):_vm._e()],1):_vm._e()],1)],1),_vm._v(" "),(_vm.isMvp && _vm.$vuetify.breakpoint.xs)?_c('v-col',{staticClass:"d-print-none",attrs:{"cols":"12"}},[_c('packages',{attrs:{"game":game}})],1):_vm._e(),_vm._v(" "),(!_vm.isEmbed)?_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","sm":_vm.isMvp ? 6 : false,"md":_vm.isMvp || _vm.layout == 2 ? 4 : false,"lg":_vm.isMvp ? 4 : false}},[_c('v-row',{class:_vm.isMvp
                                                ? 'justify-center justify-md-start'
                                                : '',attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('game-bulletins',{attrs:{"game":game}})],1),_vm._v(" "),_c('v-row',{class:_vm.layout == 2 ? 'ml-1' : '',attrs:{"no-gutters":""}},[(
                                                    _vm.showingsSatDvi(game)
                                                        .length > 0
                                                )?[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.showHeader)?_c('h4',{staticClass:"font-weight-bold",class:_vm.layout == 1
                                                                ? 'my-3 ml-3'
                                                                : ''},[_vm._v("\n                                                        AVAILABLE ON\n                                                    ")]):_vm._e(),_vm._v(" "),(!_vm.isMvp)?[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.showingsSatDvi(
                                                                    game
                                                                )),function(showing,showing_index){return _c('v-col',{key:showing_index,attrs:{"cols":"auto"},on:{"click":function($event){return _vm.liteChannel(
                                                                        game_index,
                                                                        showing_index
                                                                    )}}},[_c('channel',{attrs:{"channel":showing.channel}})],1)}),1)]:_vm._e()],2)]:_vm._e(),_vm._v(" "),(
                                                    _vm.showingsSatOnly(game)
                                                        .length > 0
                                                )?[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.showHeader)?_c('h4',{staticClass:"font-weight-bold",class:_vm.layout == 1
                                                                ? 'my-3 ml-3'
                                                                : ''},[_vm._v("\n                                                        "+_vm._s(_vm.showingsDviOnly(
                                                                game
                                                            ).length > 0
                                                                ? "VIA SATELLITE"
                                                                : "AVAILABLE ON")+"\n                                                    ")]):_vm._e(),_vm._v(" "),(!_vm.isMvp)?[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.showingsSatOnly(
                                                                    game
                                                                )),function(showing,showing_index){return _c('v-col',{key:showing_index,attrs:{"cols":"auto"},on:{"click":function($event){return _vm.liteChannel(
                                                                        game_index,
                                                                        showing_index
                                                                    )}}},[_c('channel',{attrs:{"channel":showing.channel}})],1)}),1)]:_vm._e()],2)]:_vm._e(),_vm._v(" "),(
                                                    _vm.showingsDviOnly(game)
                                                        .length > 0
                                                )?[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-divider',{class:_vm.layout == 1
                                                                ? 'ml-2'
                                                                : 'mx-2',attrs:{"vertical":""}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.showHeader)?_c('h4',{staticClass:"font-weight-bold",class:_vm.layout == 1
                                                                ? 'my-3 ml-3'
                                                                : ''},[_vm._v("\n                                                        VIA INTERNET\n                                                    ")]):_vm._e(),_vm._v(" "),(!_vm.isMvp)?[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.showingsDviOnly(
                                                                    game
                                                                )),function(showing,showing_index){return _c('v-col',{key:showing_index,attrs:{"cols":"auto"},on:{"click":function($event){return _vm.liteChannel(
                                                                        game_index,
                                                                        showing_index
                                                                    )}}},[_c('channel',{attrs:{"channel":showing.channel}})],1)}),1)]:_vm._e()],2)]:_vm._e(),_vm._v(" "),(
                                                    _vm.showingsNonDtv(game)
                                                        .length > 0
                                                )?[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.showHeader)?_c('h4',{staticClass:"font-weight-bold",class:_vm.layout == 1
                                                                ? 'my-3 ml-3'
                                                                : ''},[_vm._v("\n                                                        OTHER SOURCES\n                                                    ")]):_vm._e(),_vm._v(" "),(!_vm.isMvp)?[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.showingsNonDtv(
                                                                    game
                                                                )),function(showing,showing_index){return _c('v-col',{key:showing_index,attrs:{"cols":"auto"},on:{"click":function($event){return _vm.liteChannel(
                                                                        game_index,
                                                                        showing_index
                                                                    )}}},[_c('channel',{attrs:{"channel":showing.channel}})],1)}),1)]:_vm._e()],2)]:_vm._e()],2)],1)],1):_vm._e(),_vm._v(" "),(
                                        _vm.isMvp && _vm.$vuetify.breakpoint.smAndUp
                                    )?_c('v-col',{staticClass:"d-print-none",attrs:{"cols":"6","md":"12","lg":"2"}},[_c('packages',{attrs:{"game":game}})],1):_vm._e(),_vm._v(" "),(!_vm.isEmbed)?_c('v-col',{attrs:{"cols":"12"}},[_c('showings',{ref:"showings",refInFor:true,staticClass:"mx-2",attrs:{"game":game}})],1):_vm._e()],1)],1),_vm._v(" "),(false)?[(!_vm.isEmbed)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"square-btn mr-2",attrs:{"color":"primary","dark":"","large":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mx-auto",attrs:{"large":""}},[_vm._v("mdi-map-marker-radius-outline")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Find this game at a bar or\n                                        restaurant")])])],1):_vm._e()]:_vm._e()],2)],1)],1)],1)],1)}),_vm._v(" "),(!_vm.isEmbed)?_c('v-row',{staticClass:"d-print-none mb-16",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center gray--text mb-16 mt-5"},[_vm._v("\n            No more events listed.\n        ")])],1):_vm._e(),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.hypeDialogOpen),callback:function ($$v) {_vm.hypeDialogOpen=$$v},expression:"hypeDialogOpen"}},[_c('hype-explainer',{on:{"done":function($event){_vm.hypeDialogOpen = false}}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }