<template>
    <v-row class="mt-10 mb-16">
        <v-col class="text-center">
            <div class="text-h4 font-weight-bold">Loading...</div>
            <v-progress-circular
                :size="70"
                :width="7"
                color="blue"
                indeterminate
            ></v-progress-circular>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Loading",
};
</script>
