/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import "./bootstrap";

import Vue from "vue";
import Vuex from 'vuex';
import VueRouter from "vue-router";
import Vuetify from "vuetify";
import VueMeta from 'vue-meta';
import VueResizeText from 'vue-resize-text';
import moment from 'moment-timezone';
import {
    extendMoment
} from 'moment-range';
//import firebase from 'firebase';
import VueDragscroll from 'vue-dragscroll'
//import VueGTag from "vue-gtag";
import VueShepherd from 'vue-shepherd';
import Hotjar from 'vue-hotjar';

/**
 * Load Vue Configs (Store, Router)
 */
import store from './store';
import router from './router';

/*
Footer Icons
*/

import LinkedIn from "./components/shared/Icons/LinkedIn";
import Facebook from "./components/shared/Icons/Facebook";
import Twitter from "./components/shared/Icons/Twitter";
import Instagram from "./components/shared/Icons/Instagram";
import Youtube from "./components/shared/Icons/Youtube";
import Tiktok from "./components/shared/Icons/Tiktok";
import ChevronUp from "./components/shared/Icons/ChevronUp";
import ChevronDown from "./components/shared/Icons/ChevronDown";
import Olympics from "./components/shared/Icons/Sports/Olympics";
import Pickleball from "./components/shared/Icons/Sports/Pickleball";
import Jaialai from "./components/shared/Icons/Sports/Jaialai";
import afterEachHook from './router/afterEachHook';

router.afterEach(afterEachHook);

/**
 * Load Main Component
 */
import App from "./components/App";

/**
 * Register plugins
 */
window.VueMeta = VueMeta;
window.Vuex = Vuex;
window.VueRouter = VueRouter;
window.Vuetify = Vuetify;

//Moment.js init
window.moment = extendMoment(moment);
moment().format();

Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})

Vue.use(Vuetify);

Vue.use(VueResizeText);

Vue.use(VueDragscroll)

// Vue.use(VueGTag, {
//     config: {
//         id: process.env.MIX_GOOGLE_TAG_ID
//     },
// }, router);

Vue.use(VueShepherd);
Vue.use(require('vue-script2'))

Vue.use(Hotjar, {
    id: process.env.MIX_HOTJAR_ID
});

Vue.component('InfiniteLoading', require('vue-infinite-loading').default);

import ApiService from './api/api.service';
import _ from "lodash";
ApiService.init();

/**
 * Register Mixins
 */
Vue.mixin({
    data() {
        return {
            showBetaFeatures: process.env.MIX_BETA_FEATURES === "true",
            isFramed: window.location !== window.parent.location,
            appUrl: process.env.MIX_APP_URL,
        };
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        isDtvMobile() {
            return this.$vuetify.breakpoint.width < 1080;
        },
        isDebugMode() {
            return this.$route.query.test === '32jh4k234jhlk243hjlk';
        },
        isShowingAds() {
            return this.$route.query.ads === 'true';
        },
        isEmbed() {
            return this.$route.name === 'embed';
        },
        isMvp() {
            return this.$route.name === 'mvp';
        }
    },
    methods: {
        toTitleCase(str) {
            if (!str) {
                return '';
            }
            return str.replace(
                /\w\S*/g,
                function (txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },
        forcePlus(value) {
            //If value is null, return -
            if (value === null) {
                return "-";
            }
            return (value <= 0 ? '' : '+') + value;
        },
        handle_function_call(function_name) {
            this[function_name]()
        },
        checkPasswordStrength(password) {

            // Test for:
            // 1. Uppercase Alphabet
            // 2. Lowercase Alphabet
            // 3. Numeric Digits
            // 4. Special Characters
            // 5. Length

            //Regular Expressions
            let regex = new Array();
            regex.push("[A-Z]"); //For Uppercase Alphabet
            regex.push("[a-z]"); //For Lowercase Alphabet
            regex.push("[A-Z]"); //For Numeric Digits
            regex.push("[$@$!%*#?&]"); //For Special Characters

            let passed = 0;

            //Validation for each Regular Expression
            for (let i = 0; i < regex.length; i++) {
                if ((new RegExp(regex[i])).test(password)) {
                    passed++;
                }
            }

            //Validation for Length of Password
            if (passed > 2 && password.length >= 8) {
                passed++;
            }

            return {
                strength: passed / 5 * 100,
                tests: [{
                    passed: new RegExp("[a-z]").test(password),
                    message: "At least one lowercase letter"
                },
                {
                    passed: new RegExp("[A-Z]").test(password),
                    message: "At least one capital letter"
                },
                {
                    passed: new RegExp("[0-9]").test(password),
                    message: "At least one number"
                },
                {
                    passed: new RegExp("[$@$!%*#?&]").test(password),
                    message: "At least one special character"
                },
                {
                    passed: password.length >= 8,
                    message: "Be at least 8 characters"
                }
                ]
            };
        },
        searchArrayByKey(key, list, field = 'id', seeking = 'object') {
            for (var i = 0; i < list.length; i++) {
                if (list[i][field] == key) {
                    if (seeking == 'object') {
                        return list[i];
                    } else if (seeking == 'name') {
                        return list[i].name;
                    } else {
                        return i;
                    }

                }
            }
        },
        scrollToBottom() {
            this.$nextTick(() => {
                setTimeout(() => {
                    window.scrollTo(0, document.body.scrollHeight);
                }, 500);
            });
        },
        thuuzColor(value) {
            switch (true) {
                case (value >= 85):
                    return 'thuuzGreat';
                case (value >= 65):
                    return 'thuuzGood';
                case (value >= 40):
                    return 'thuuzOk';
                default:
                    return 'thuuzDull';
            }
        },
        ordinalSuffixOf(i) {
            i = parseInt(i);
            var j = i % 10,
                k = i % 100;
            if (j == 1 && k != 11) {
                return i + "st";
            }
            if (j == 2 && k != 12) {
                return i + "nd";
            }
            if (j == 3 && k != 13) {
                return i + "rd";
            }
            return i + "th";
        },
        convertToSlug(text) {
            return text.toLowerCase()
                .replace(/[^\w ]+/g, '')
                .replace(/ +/g, '-');
        },
        rowClass(item) {
            return "datatable-row";
        },
    }
});
/**
 * Initialize VueJS
 */
const app = new Vue({
    el: "#app",
    store,
    router,
    vuetify: new Vuetify({
        theme: {
            light: true,
            themes: {
                light: {
                    primary: "#196DD4",
                    lightBlue: "#196DD4",
                    darkBlue: "#2B5680",
                    blue: "#196DD4",
                    red: "#990000",
                    burgundy: "#ad4b4b",
                    pink: "#EF59D8",
                    orange: "#FF6600",
                    gold: "#FFC400",
                    green: "#009688",
                    lightGray: "#FBFBFB",
                    mediumGray: "#CCCCCC",
                    gray: "#777",
                    darkGray: "#333333",
                    darkerGray: "#121212",
                    background: "#FFFFFF",
                    thuuzGreat: "#e82f28",
                    thuuzGood: "#f0883a",
                    thuuzOk: "#fbde63",
                    thuuzDull: "#e4e7bd",
                }
            }
        },
        icons: {
            values: {
                linkedin: {
                    component: LinkedIn
                },
                facebook: {
                    component: Facebook

                },
                twitter: {
                    component: Twitter
                },
                instagram: {
                    component: Instagram
                },
                youtube: {
                    component: Youtube
                },
                tiktok: {
                    component: Tiktok
                },
                chevronup: {
                    component: ChevronUp
                },
                chevrondown: {
                    component: ChevronDown
                },
                olympics: {
                    component: Olympics
                },
                pickleball: {
                    component: Pickleball
                },
                jaialai: {
                    component: Jaialai
                }
            }
        }
    }),
    render: h => h(App)
});
