<template>
    <div>
        <v-row>
            <v-col cols="12">
                Positions:
                <v-tooltip
                    v-for="(position, index) in positions"
                    :key="index"
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            v-bind="attrs"
                            v-on="on"
                            @click="selectedPosition = position.abbreviation"
                            mandatory
                            class="ma-2"
                            :color="
                                position.abbreviation === selectedPosition
                                    ? 'primary'
                                    : ''
                            "
                        >
                            {{ position.abbreviation }}
                        </v-chip>
                    </template>
                    <span>{{ position.name }}</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <!-- Player Data Table -->
        <v-data-table
            :headers="playerHeaders"
            :items="filteredPlayers"
            :item-class="rowClass"
            :search="search"
        >
            <template v-slot:top>
                <v-text-field
                    prepend-icon="mdi-magnify"
                    v-model="search"
                    label="Search"
                    class="mx-4"
                />
            </template>
            <template v-slot:item.positions="{ item }">
                <span v-for="(position, index) in item.positions" :key="index">
                    {{ position.abbreviation }}
                </span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: "NFL",
    props: {
        league: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            search: "",
            selectedPosition: "All",
            playerHeaders: [
                { text: "Last Name", value: "lastName" },
                { text: "First Name", value: "firstName" },
                { text: "Team", value: "team.nickname" },
                { text: "Position", value: "positions[0].abbreviation" },
                { text: "Draft Year", value: "draft.year" },
            ],
        };
    },
    computed: {
        positions() {
            const uniqueAbbreviations = new Set(
                this.league.players.flatMap((p) =>
                    p.positions.map((pos) => pos.abbreviation)
                )
            );
            const positions = Array.from(uniqueAbbreviations).map(
                (abbreviation) => {
                    return this.league.players
                        .flatMap((p) => p.positions)
                        .find((pos) => pos.abbreviation === abbreviation);
                }
            );
            positions.unshift({ abbreviation: "All", name: "All Positions" });
            return positions;
        },
        filteredPlayers() {
            const players =
                this.selectedPosition === "All"
                    ? this.league.players
                    : this.league.players.filter((player) =>
                          player.positions.some(
                              (pos) =>
                                  pos.abbreviation === this.selectedPosition
                          )
                      );
            return players
                .slice()
                .sort((a, b) => a.lastName.localeCompare(b.lastName));
        },
    },
    methods: {
        positionsString(positions) {
            return positions.map((p) => p.abbreviation).join(", ");
        },
    },
};
</script>

<style scoped>
.search-field {
    padding-top: 20px;
}

.search-field >>> .v-label {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.search-field >>> input {
    font-size: 20px;
}

.search-field >>> .v-icon {
    font-size: 40px;
}
::v-deep .datatable-row:nth-child(odd) {
    background-color: #f9f9f9 !important;
}
::v-deep .datatable-row:nth-child(even) {
    background-color: #b3d1f2 !important;
}
</style>
