/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS_ACTION
} from "./actions.type";


/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    SET_ERROR,
    CLEAR_ERRORS
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        login: {
            message: null,
            errors: {}
        },
        register: {
            message: null,
            errors: {}
        },
        forgot: {
            message: null,
            errors: {}
        },
        reset: {
            message: null,
            errors: {}
        },
        reset_password: {
            message: null,
            errors: {}
        },
        games: {
            message: null,
            errors: {}
        },
        options: {
            message: null,
            errors: {}
        },
        groups: {
            message: null,
            errors: {}
        },
        screens: {
            message: null,
            errors: {}
        },
        stats: {
            message: null,
            errors: {}
        },
    };
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    getMessage: (state) => (target) => {
        return state[target].message ? state[target].message : null;
    },
    getErrors: (state) => (target) => {
        return typeof state[target].errors != "undefined" ? state[target].errors : null;
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [CLEAR_ERRORS](state) {
//         console.log("CLEAR_ERRORS");
        Object.assign(state, getDefaultState())
    },
    [SET_ERROR](state, {
        target,
        message,
        errors
    }) {
//         console.log("SET_ERROR");
        state[target].message = message;
        state[target].errors = errors;
    },
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [CLEAR_ERRORS_ACTION](context) {
        context.commit(CLEAR_ERRORS);
    }
}

export default {
    getters,
    mutations,
    state
}
