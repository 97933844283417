import ApiService from "../api/api.service";

/*------------------------------------*\
    Action Constants
\*------------------------------------*/
import {
    GET_ADDONS,
    GET_SELECTED_ADDONS,
    POST_SELECTED_ADDONS,
} from "./actions.type";

/*------------------------------------*\
    Mutation Constants
\*------------------------------------*/
import {
    CLEAR_ERRORS,
    SET_ERROR,
    SET_ADDONS,
    SET_SELECTED_ADDONS
} from "./mutations.type";

/*------------------------------------*\
    State
\*------------------------------------*/
const getDefaultState = () => {
    return {
        addons: [],
        selectedAddons: [],
    }
};

const state = getDefaultState();

/*------------------------------------*\
    Getters
\*------------------------------------*/
const getters = {
    getAddons(state) {
        return state.addons;
    },
    getSelectedAddons(state) {
        return state.selectedAddons;
    },
};

/*------------------------------------*\
    Mutations
\*------------------------------------*/
const mutations = {
    [SET_ADDONS](state, data) {
//         console.log("SET_ADDONS");
        state.addons = data;
    },
    [SET_SELECTED_ADDONS](state, data) {
//         console.log("SET_SELECTED_ADDONS");
        state.selectedAddons = data;
    }
};

/*------------------------------------*\
    Actions
\*------------------------------------*/
const actions = {
    [GET_ADDONS](context) {
//         console.log("GET_ADDONS");
        if(Object.keys(context.getters.getAddons).length > 0) {
            return;
        }
        context.commit(CLEAR_ERRORS);
        return new Promise((resolve, reject) => {
            ApiService.get("/options/addons")
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_ADDONS, data
                    );
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'addons',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [GET_SELECTED_ADDONS](context) {
//         console.log("GET_SELECTED_ADDONS");
        context.commit(CLEAR_ERRORS);
        return new Promise((resolve, reject) => {
            ApiService.get("/options/addons/selected")
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_SELECTED_ADDONS, data
                    );
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'addons',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },
    [POST_SELECTED_ADDONS](context, details) {
//         console.log("POST_SELECTED_ADDONS");
        context.commit(CLEAR_ERRORS);
        return new Promise((resolve, reject) => {
            ApiService.post("/options/addons/selected", {'addons': details})
                .then(({
                    data
                }) => {
                    context.commit(CLEAR_ERRORS);
                    context.commit(
                        SET_SELECTED_ADDONS, data
                    );
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(
                        SET_ERROR, {
                            target: 'addons',
                            message: response.data.error
                        }
                    );
                    reject(response);
                });
        });
    },

};

export default {
    getters,
    actions,
    mutations,
    state
}
