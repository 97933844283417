<template>
    <v-row
        no-gutters
        justify="center"
    >
        <v-col cols="auto">
            <v-img
                width="80"
                height="80"
                contain
                class="channel-image"
                :src="channelLogo"
                :alt="channel.title_display + ' Logo'"
                style="filter: url(#drop-shadow);"
            />
            <svg
                width="0"
                height="0"
            >
                <defs>
                    <filter
                        id="drop-shadow"
                        x="-50%"
                        y="-50%"
                        width="200%"
                        height="200%"
                    >
                        <feGaussianBlur
                            in="SourceAlpha"
                            stdDeviation="3"
                        />
                        <feOffset
                            dx="2"
                            dy="2"
                        />
                        <feComponentTransfer>
                            <feFuncA
                                type="linear"
                                slope="0.3"
                            />
                        </feComponentTransfer>
                        <feMerge>
                            <feMergeNode />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>
            </svg>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "RsnChannelLogo",
    props: { channel: Object },
    data() {
        return {
            failed_image: false,
        };
    },
    computed: {
        channelLogo() {
            if (this.failed_image) return "https://www.directv.com/img/ent/global/missing_channel_logo.png";

            if (Number.isInteger(this.channel.logo)) {
                return `https://www.directv.com/dtvassets/dtv/teamsite/images/logos/channels/dark/medium/${this.channel.logo}.png`;
            } else {
                return `${this.appUrl}/img/logos/channels/${this.channel.logo}.svg`;
            }
        }
    },
    methods: {
        onImgError(event) {
            console.log("image error");
            this.failed_image = true;
        },
    },
};
</script>

<style scoped>
.channel-logo-shadow {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06),
        0 4px 6px -1px rgba(0, 0, 0, 0.1) !important;
}
</style>
