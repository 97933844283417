var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.layout)?_c('v-sheet',{staticClass:"text-center text-uppercase",class:_vm.sheetClass,style:(_vm.isEmbed ? 'background-color: #FCFCFC;' : ''),attrs:{"justify":"center"}},[(_vm.team.logo_exists)?_c(_vm.teamLinkOrDiv,{tag:"component",attrs:{"href":_vm.team.show_season_schedule
                ? _vm.appUrl +
                  '/leagues/' +
                  _vm.team.league_slug.toLowerCase() +
                  '/teams/' +
                  _vm.team.text_id.toLowerCase()
                : false}},[(!_vm.isNfl)?_c('v-img',{staticClass:"mx-auto d-print-none",attrs:{"content-class":"contain-paint","contain":"","height":_vm.$vuetify.breakpoint.xl ? 40 : 30,"max-width":_vm.$vuetify.breakpoint.xl ? 60 : 40,"src":_vm.logoSource,"alt":_vm.team.display_name_short + ' Logo'}}):_vm._e()],1):_vm._e(),_vm._v(" "),(!_vm.isEmbed && _vm.team.show_season_schedule)?_c('a',{attrs:{"href":_vm.appUrl +
            '/leagues/' +
            _vm.team.league_slug.toLowerCase() +
            '/teams/' +
            _vm.team.text_id.toLowerCase()}},[_vm._v("\n        "+_vm._s(_vm.isNfl ? _vm.team.espn_name_team : _vm.team.display_name_short)+"\n    ")]):[_vm._v("\n        "+_vm._s(_vm.isNfl ? _vm.team.espn_name_team : _vm.team.display_name_short)+"\n    ")]],2):_c(_vm.teamLinkOrDiv,{tag:"component",attrs:{"href":_vm.appUrl +
        '/leagues/' +
        _vm.team.league_slug.toLowerCase() +
        '/teams/' +
        _vm.team.text_id.toLowerCase()}},[(_vm.team.logo_exists)?_c('v-img',{staticClass:"mx-auto d-print-none mb-2",attrs:{"content-class":"contain-paint","transition":"none","contain":"","max-width":"81","height":"54","src":_vm.logoSource,"alt":_vm.team.display_name_short + ' Logo'}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-uppercase font-weight-thin team-name my-3"},[_vm._v("\n        "+_vm._s(_vm.isNfl ? _vm.team.espn_name_team : _vm.team.display_name_short)+"\n    ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }