<template>
    <div>
        <v-row
            no-gutters
            v-for="(game, game_index) in games"
            :key="game.id"
            class="d-print-none mt-3"
        >
            <v-col>
                <v-row
                    no-gutters
                    v-if="showListHeader(game_index, false)"
                    class="dense-header mx-md-3 my-5 pa-3"
                >
                    <v-col cols="12">
                        <h2 class="group-header-text">
                            {{ titleDisplay(game) }}
                        </h2>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-row
                            no-gutters
                            align="center"
                            class="mx-md-3 mb-5 py-3"
                            :class="isEmbed ? '' : 'dense-border'"
                            :style="
                                isEmbed
                                    ? `border: 4px solid #${embed.customization.primary};border-radius:4px;`
                                    : ''
                            "
                        >
                            <v-col>
                                <v-row no-gutters>
                                    <v-col
                                        cols="12"
                                        :md="isMvp || layout == 2 ? 8 : false"
                                        :lg="isMvp ? 6 : false"
                                    >
                                        <v-row no-gutters align="center">
                                            <v-col
                                                cols="auto"
                                                v-if="!isEmbed && layout == 1"
                                            >
                                                <v-avatar
                                                    :color="
                                                        game.thuuz_game
                                                            ? thuuzColor(
                                                                  game
                                                                      .thuuz_game
                                                                      .gex_default
                                                              )
                                                            : 'white'
                                                    "
                                                    class="ml-2"
                                                    @click="
                                                        hypeDialogOpen = true
                                                    "
                                                    size="10"
                                                >
                                                </v-avatar>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-card
                                                    flat
                                                    tile
                                                    :style="
                                                        isEmbed
                                                            ? 'background-color: #FCFCFC;'
                                                            : ''
                                                    "
                                                    v-if="game.league.has_logo"
                                                    :href="
                                                        !isEmbed &&
                                                        game.league
                                                            .show_season_schedule
                                                            ? `${appUrl}/leagues/${game.league.slug.toLowerCase()}/schedule`
                                                            : ''
                                                    "
                                                >
                                                    <!-- <v-img
                                                        v-if="isNfl(game)"
                                                        class="ml-3 contain-paint"
                                                        contain
                                                        :width="$vuetify.breakpoint.xs ? 30 : 50"
                                                        :height="$vuetify.breakpoint.xs ? 30 : 50"
                                                        :alt="game.league.title + ' Logo'"
                                                    >
                                                        <nfl-plain-logo />
                                                    </v-img> -->

                                                    <v-img
                                                        class="ml-3 contain-paint"
                                                        contain
                                                        :src="`${appUrl}/img/logos/leagues/${game.league.slug}.svg`"
                                                        :width="
                                                            $vuetify.breakpoint
                                                                .xs
                                                                ? 30
                                                                : 50
                                                        "
                                                        :height="
                                                            $vuetify.breakpoint
                                                                .xs
                                                                ? 30
                                                                : 50
                                                        "
                                                        :alt="
                                                            game.league.title +
                                                            ' Logo'
                                                        "
                                                    />
                                                </v-card>
                                                <sport-badge-dense
                                                    class="ml-3"
                                                    :game="game"
                                                    v-else
                                                />
                                            </v-col>
                                            <v-col
                                                style="white-space: nowrap"
                                                cols="2"
                                                sm="2"
                                                class="ml-2 text-center"
                                            >
                                                <time-display :game="game" />
                                            </v-col>
                                            <v-col>
                                                <div
                                                    v-if="game.description"
                                                    class="mx-2 mx-sm-5 text-center text-description"
                                                >
                                                    <div
                                                        v-if="
                                                            $vuetify.breakpoint
                                                                .smAndUp
                                                        "
                                                        class="text-sm-description"
                                                    >
                                                        {{ game.description }}
                                                    </div>
                                                    <div
                                                        v-else
                                                        class="text-description"
                                                    >
                                                        {{ game.description }}
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <teams :game="game" />
                                                </div>
                                            </v-col>
                                            <v-col
                                                cols="auto"
                                                sm="3"
                                                md="6"
                                                v-if="!isMvp && layout != 2"
                                            >
                                                <v-row
                                                    no-gutters
                                                    justify="end"
                                                    v-if="
                                                        $vuetify.breakpoint
                                                            .smAndUp &&
                                                        !!game.thuuz_game
                                                    "
                                                >
                                                    <v-col
                                                        v-if="
                                                            $vuetify.breakpoint
                                                                .mdAndUp
                                                        "
                                                        class="px-3"
                                                    >
                                                        {{
                                                            game.thuuz_game
                                                                .teaser_default
                                                        }}
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <compact-win-probability
                                                            :game="game"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col
                                        cols="12"
                                        v-if="isMvp && $vuetify.breakpoint.xs"
                                        class="d-print-none"
                                    >
                                        <packages :game="game" />
                                    </v-col>

                                    <v-col
                                        cols="12"
                                        :sm="isMvp ? 6 : false"
                                        :md="isMvp || layout == 2 ? 4 : false"
                                        :lg="isMvp ? 4 : false"
                                        class="mt-2"
                                        v-if="!isEmbed"
                                    >
                                        <v-row
                                            no-gutters
                                            :class="
                                                isMvp
                                                    ? 'justify-center justify-md-start'
                                                    : ''
                                            "
                                        >
                                            <v-col cols="12">
                                                <game-bulletins :game="game" />
                                            </v-col>
                                            <v-row
                                                no-gutters
                                                :class="
                                                    layout == 2 ? 'ml-1' : ''
                                                "
                                            >
                                                <template
                                                    v-if="
                                                        showingsSatDvi(game)
                                                            .length > 0
                                                    "
                                                >
                                                    <v-col cols="auto">
                                                        <h4
                                                            v-if="showHeader"
                                                            class="font-weight-bold"
                                                            :class="
                                                                layout == 1
                                                                    ? 'my-3 ml-3'
                                                                    : ''
                                                            "
                                                        >
                                                            AVAILABLE ON
                                                        </h4>
                                                        <template v-if="!isMvp">
                                                            <v-row no-gutters>
                                                                <v-col
                                                                    cols="auto"
                                                                    v-for="(
                                                                        showing,
                                                                        showing_index
                                                                    ) in showingsSatDvi(
                                                                        game
                                                                    )"
                                                                    :key="
                                                                        showing_index
                                                                    "
                                                                    @click="
                                                                        liteChannel(
                                                                            game_index,
                                                                            showing_index
                                                                        )
                                                                    "
                                                                >
                                                                    <channel
                                                                        :channel="
                                                                            showing.channel
                                                                        "
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                    </v-col>
                                                </template>
                                                <template
                                                    v-if="
                                                        showingsSatOnly(game)
                                                            .length > 0
                                                    "
                                                >
                                                    <v-col cols="auto">
                                                        <h4
                                                            v-if="showHeader"
                                                            class="font-weight-bold"
                                                            :class="
                                                                layout == 1
                                                                    ? 'my-3 ml-3'
                                                                    : ''
                                                            "
                                                        >
                                                            {{
                                                                showingsDviOnly(
                                                                    game
                                                                ).length > 0
                                                                    ? "VIA SATELLITE"
                                                                    : "AVAILABLE ON"
                                                            }}
                                                        </h4>
                                                        <template v-if="!isMvp">
                                                            <v-row no-gutters>
                                                                <v-col
                                                                    cols="auto"
                                                                    v-for="(
                                                                        showing,
                                                                        showing_index
                                                                    ) in showingsSatOnly(
                                                                        game
                                                                    )"
                                                                    :key="
                                                                        showing_index
                                                                    "
                                                                    @click="
                                                                        liteChannel(
                                                                            game_index,
                                                                            showing_index
                                                                        )
                                                                    "
                                                                >
                                                                    <channel
                                                                        :channel="
                                                                            showing.channel
                                                                        "
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                    </v-col>
                                                </template>
                                                <template
                                                    v-if="
                                                        showingsDviOnly(game)
                                                            .length > 0
                                                    "
                                                >
                                                    <v-col cols="auto">
                                                        <v-divider
                                                            vertical
                                                            :class="
                                                                layout == 1
                                                                    ? 'ml-2'
                                                                    : 'mx-2'
                                                            "
                                                        />
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        <h4
                                                            v-if="showHeader"
                                                            class="font-weight-bold"
                                                            :class="
                                                                layout == 1
                                                                    ? 'my-3 ml-3'
                                                                    : ''
                                                            "
                                                        >
                                                            VIA INTERNET
                                                        </h4>
                                                        <template v-if="!isMvp">
                                                            <v-row no-gutters>
                                                                <v-col
                                                                    cols="auto"
                                                                    v-for="(
                                                                        showing,
                                                                        showing_index
                                                                    ) in showingsDviOnly(
                                                                        game
                                                                    )"
                                                                    :key="
                                                                        showing_index
                                                                    "
                                                                    @click="
                                                                        liteChannel(
                                                                            game_index,
                                                                            showing_index
                                                                        )
                                                                    "
                                                                >
                                                                    <channel
                                                                        :channel="
                                                                            showing.channel
                                                                        "
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                    </v-col>
                                                </template>
                                                <template
                                                    v-if="
                                                        showingsNonDtv(game)
                                                            .length > 0
                                                    "
                                                >
                                                    <v-col cols="auto">
                                                        <h4
                                                            v-if="showHeader"
                                                            class="font-weight-bold"
                                                            :class="
                                                                layout == 1
                                                                    ? 'my-3 ml-3'
                                                                    : ''
                                                            "
                                                        >
                                                            OTHER SOURCES
                                                        </h4>
                                                        <template v-if="!isMvp">
                                                            <v-row no-gutters>
                                                                <v-col
                                                                    cols="auto"
                                                                    v-for="(
                                                                        showing,
                                                                        showing_index
                                                                    ) in showingsNonDtv(
                                                                        game
                                                                    )"
                                                                    :key="
                                                                        showing_index
                                                                    "
                                                                    @click="
                                                                        liteChannel(
                                                                            game_index,
                                                                            showing_index
                                                                        )
                                                                    "
                                                                >
                                                                    <channel
                                                                        :channel="
                                                                            showing.channel
                                                                        "
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                    </v-col>
                                                </template>
                                            </v-row>
                                            <!-- <v-col
                                                cols="auto"
                                                v-for="(showing, showing_index) in game.showings"
                                                :key="showing_index"
                                                class="mt-0 ml-2"
                                                @click="liteChannel(game_index, showing_index)"
                                            >
                                                <channel
                                                    :channel="showing.channel"
                                                    :index="showing_index"
                                                />

                                            </v-col> -->
                                        </v-row>
                                    </v-col>

                                    <v-col
                                        cols="6"
                                        md="12"
                                        lg="2"
                                        v-if="
                                            isMvp && $vuetify.breakpoint.smAndUp
                                        "
                                        class="d-print-none"
                                    >
                                        <packages :game="game" />
                                    </v-col>

                                    <v-col cols="12" v-if="!isEmbed">
                                        <showings
                                            :game="game"
                                            ref="showings"
                                            class="mx-2"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <template v-if="false">
                                <v-col cols="auto" v-if="!isEmbed">
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                color="primary"
                                                dark
                                                large
                                                class="square-btn mr-2"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon large class="mx-auto"
                                                    >mdi-map-marker-radius-outline</v-icon
                                                >
                                            </v-btn>
                                        </template>
                                        <span
                                            >Find this game at a bar or
                                            restaurant</span
                                        >
                                    </v-tooltip>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="d-print-none mb-16" no-gutters v-if="!isEmbed">
            <v-col class="text-center gray--text mb-16 mt-5">
                No more events listed.
            </v-col>
        </v-row>
        <v-dialog v-model="hypeDialogOpen" max-width="400">
            <hype-explainer @done="hypeDialogOpen = false" />
        </v-dialog>
    </div>
</template>

<script>
import Showings from "./Showings";
import Packages from "./Packages";
import TimeDisplay from "./Shared/TimeDisplay";
import Channel from "./Channel";
import SportBadgeDense from "../Dense/SportBadgeDense";
import Teams from "./Teams";
import HypeExplainer from "./Hype/HypeExplainer";
import CompactWinProbability from "./Hype/CompactWinProbability";
import GameBulletins from "./GameBulletins";
import NflPlainLogo from "./NflPlainLogo";

export default {
    name: "Compact",
    components: {
        Showings,
        Packages,
        TimeDisplay,
        Channel,
        SportBadgeDense,
        Teams,
        HypeExplainer,
        CompactWinProbability,
        GameBulletins,
        NflPlainLogo,
    },
    props: {
        games: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            hypeDialogOpen: false,
        };
    },
    computed: {
        layout() {
            return this.$store.getters.getCurrentSelectedOptions("layout");
        },
        groupBy() {
            return this.$store.getters.getCurrentSelectedOptions("groups");
        },
        timezone() {
            return this.$store.getters.getCurrentSelectedOptions(
                "timezoneString"
            );
        },
        embed() {
            return this.$store.getters.getCurrentEmbed;
        },
        isCommercial() {
            return this.$store.getters.getIsCommercial;
        },
    },
    methods: {
        isNfl(game) {
            if (!this.isCommercial && game.league_id == 10) {
                return true;
            }
            return false;
        },
        showingsSatDvi(game) {
            return game.showings.filter(
                (showing) =>
                    showing.channel.is_sat &&
                    showing.channel.is_dvi &&
                    !showing.channel.nondtv
            );
        },
        showingsSatOnly(game) {
            return game.showings.filter(
                (showing) =>
                    showing.channel.is_sat &&
                    !showing.channel.is_dvi &&
                    !showing.channel.nondtv
            );
        },
        showingsDviOnly(game) {
            return game.showings.filter(
                (showing) =>
                    !showing.channel.is_sat &&
                    showing.channel.is_dvi &&
                    !showing.channel.nondtv
            );
        },
        showingsNonDtv(game) {
            return game.showings.filter((showing) => showing.channel.nondtv);
        },
        liteChannel(game_index, showing_index) {
            this.$refs.showings[game_index].liteChannel(showing_index);
        },
        titleDisplay(game, group = false) {
            if (
                this.$route.matched.some(
                    (r) =>
                        r.name == "league-schedule" || r.name == "team-schedule"
                )
            )
                return this.gameDate(game);

            switch (this.groupBy) {
                case 1:
                    return group ? game.sport.title : game.league.title_short;
                default:
                    return game.league.title_short;
            }
        },
        showListHeader(game_index, printView) {
            if (this.isEmbed) return false;

            if (this.isMvp) return false;

            //Season Schedule
            if (
                this.$route.matched.some(
                    (r) =>
                        r.name == "league-schedule" || r.name == "team-schedule"
                )
            ) {
                if (game_index == 0) return true;

                return (
                    this.titleDisplay(this.games[game_index]) !=
                    this.titleDisplay(this.games[game_index - 1])
                );
            }

            //If not grouping, or route has a schedule bar already, then false
            if (!this.groupBy || this.$route.meta.showbar == false) {
                if (printView) {
                    return false;
                }
                return this.layout ? false : true;
            }

            //Otherwise compare current index to previous index on desired field
            switch (this.groupBy) {
                case 1:
                    //Sport
                    //If first game, always show when grouping
                    if (game_index == 0) return true;

                    if (
                        this.games[game_index].sport_id !=
                        this.games[game_index - 1].sport_id
                    )
                        return true;
                    return false;
                case 2:
                    //League
                    //If first game, always show when grouping
                    if (game_index == 0) return true;

                    if (
                        this.games[game_index].league_id !=
                        this.games[game_index - 1].league_id
                    )
                        return true;
                    return false;
                case 3:
                    if (printView) {
                        return false;
                    }
                    return this.layout ? false : true;
            }
            return true;
        },
        gameDate(game) {
            if (!game) return null;

            let gameDateTime =
                game.showings && game.showings.length
                    ? game.showings[0].display_at
                    : game.starts_at;
            let dateTime = moment.utc(gameDateTime);
            return dateTime.tz(this.timezone).format("ddd, MMM Do");
        },
        showHeader() {
            if (this.isMvp) return false;

            return this.layout == 0;
        },
    },
};
</script>

<style scoped>
.group-header-text {
    font-size: 1.48em !important;
    letter-spacing: 0em !important;
}

.dense-border {
    border: 1px solid #196dd4;
    background: white;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.dense-header {
    background: #196dd4;
    color: white;
    font-size: 1.4em;
    font-weight: bold;
    text-transform: uppercase;
}

.square-btn {
    width: 64px !important;
    height: 64px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: unset !important;
}

.text-description {
    font-size: 1em;
    text-transform: uppercase;
}

.text-sm-description {
    font-size: 1.2em;
    text-transform: uppercase;
}
</style>
