<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-chip
                    v-for="(position, index) in positions"
                    :key="index"
                    mandatory
                    @click="selectPosition(position)"
                    class="ma-2"
                    :color="position === selectedPosition ? 'primary' : ''"
                >
                    {{ position }}
                </v-chip>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="fantasyProjections"
            item-key="playerId"
            :item-class="rowClass"
            show-expand
            single-expand
            :expanded.sync="expanded"
            class="elevation-1"
            :search="search"
            :loading="loading"
        >
            <template v-slot:top>
                <v-text-field v-model="search" label="Search" class="mx-4" />
            </template>
            <template v-slot:item.completions="{ item }">
                {{ item.completions ? Math.round(item.completions) : "" }}
            </template>

            <template v-slot:item.attempts="{ item }">
                {{ item.attempts ? Math.round(item.attempts) : "" }}
            </template>

            <template v-slot:item.passYards="{ item }">
                {{ item.passYards ? Math.round(item.passYards) : "" }}
            </template>

            <template v-slot:item.passTouchdowns="{ item }">
                {{ item.passTouchdowns ? Math.round(item.passTouchdowns) : "" }}
            </template>

            <template v-slot:item.interceptions="{ item }">
                {{ item.interceptions ? Math.round(item.interceptions) : "" }}
            </template>

            <template v-slot:item.rushes="{ item }">
                {{ item.rushes ? Math.round(item.rushes) : "" }}
            </template>

            <template v-slot:item.rushYards="{ item }">
                {{ item.rushYards ? Math.round(item.rushYards) : "" }}
            </template>

            <template v-slot:item.rushTouchdowns="{ item }">
                {{ item.rushTouchdowns ? Math.round(item.rushTouchdowns) : "" }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn text color="primary" @click="toggleExpansion(item)">
                    View Projections
                </v-btn>
            </template>
            <template v-slot:expanded-item="{ item }">
                <td :colspan="headers.length">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-list>
                                    <v-list-item
                                        v-for="projection in item.fantasyProjections"
                                        :key="projection.siteId"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ projection.name }}:
                                                {{
                                                    Math.round(
                                                        projection.points
                                                    )
                                                }}
                                                Points (Rank:
                                                {{ projection.rank }})
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-container>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: "MLB",
    data() {
        return {
            loading: true,
            search: "",
            selectedPlayer: null,
            selectedPosition: "All Offense",
            expanded: [],
            positions: ["All Offense", "QB", "RB", "WR", "TE", "K", "DEF"],
            fantasyProjections: [],
            headers: [
                { text: "First Name", value: "firstName" },
                { text: "Last Name", value: "lastName" },
                { text: "Position", value: "position" },
                { text: "Team", value: "team" },
                { text: "GP", value: "gamesPlayed" },
                { text: "Bye", value: "byeWeek" },
                { text: "Completions", value: "completions", align: "center" },
                { text: "Attempts", value: "attempts", align: "center" },
                { text: "Pass Yards", value: "passYards", align: "center" },
                { text: "Pass TDs", value: "passTouchdowns", align: "center" },
                { text: "INTs", value: "interceptions", align: "center" },
                { text: "Rushes", value: "rushes", align: "center" },
                { text: "Rush Yards", value: "rushYards", align: "center" },
                { text: "Rush TDs", value: "rushTouchdowns", align: "center" },
                // Add more headers for additional stats as needed
                {
                    text: "Fantasy Projections",
                    value: "actions",
                    sortable: false,
                },
            ],
        };
    },
    computed: {
        statsData() {
            return this.$store.getters.currentStatsData;
        },
    },
    methods: {
        toggleExpansion(item) {
            if (this.expanded.length > 0 && this.expanded[0] === item) {
                this.expanded = [];
            } else {
                this.expanded = [item];
            }
        },
        selectPosition(position) {
            this.selectedPlayer = null; // Clear the selected player
            this.selectedPosition = position;
        },
        parseFantasyProjections() {
            const data = this.statsData;

            // Loop through each eventType and grab the offensiveProjections
            for (const eventType of data.league.season.eventType) {
                for (const projection of eventType.fantasyProjections
                    .offensiveProjections) {
                    const flatData = {
                        playerId: projection.player.playerId,
                        firstName: projection.player.firstName,
                        lastName: projection.player.lastName,
                        position: projection.position,
                        team: projection.team.nickname,
                        gamesPlayed: projection.gamesPlayed,
                        byeWeek: projection.byeWeek,
                        completions: projection.completions,
                        attempts: projection.attempts,
                        passYards: projection.passYards,
                        passTouchdowns: projection.passTouchdowns,
                        interceptions: projection.interceptions,
                        rushes: projection.rushes,
                        rushYards: projection.rushYards,
                        rushTouchdowns: projection.rushTouchdowns,
                        fantasyProjections: projection.fantasyProjections,
                    };
                    this.fantasyProjections.push(flatData);
                }
            }
            this.loading = false;
        },
    },
    mounted() {
        this.parseFantasyProjections();
    },
};
</script>

<style scoped>
.search-field {
    padding-top: 20px;
}

.search-field >>> .v-label {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.search-field >>> input {
    font-size: 20px;
}

.search-field >>> .v-icon {
    font-size: 40px;
}
::v-deep .datatable-row:nth-child(odd) {
    background-color: #f9f9f9 !important;
}
::v-deep .datatable-row:nth-child(even) {
    background-color: #b3d1f2 !important;
}
</style>
