<template>
    <v-badge
        v-resize="onResize"
        avatar
        overlap
        :color="!isEmbed ? 'primary' : ''"
        :style="isEmbed ? `color: #${embed.customization.alternative}` : ''"
        class="sport-badge"
        :offset-x="-10 * smallScreenScale"
        :offset-y="40"
    >
        <template v-slot:badge>
            <v-avatar color="white" class="sport-badge-avatar">
                <v-card
                    v-if="game.league.has_logo"
                    :href="
                        !isEmbed && game.league.show_season_schedule
                            ? `${appUrl}/leagues/${game.league.slug.toLowerCase()}/schedule`
                            : ''
                    "
                    flat
                    tile
                >
                    <v-img
                        class="mt-1 contain-paint"
                        contain
                        :src="`${appUrl}/img/logos/leagues/${game.league.slug}.svg`"
                        :width="52 * smallScreenScale"
                        :height="52 * smallScreenScale"
                        :alt="game.league.name + ' Logo'"
                    />
                </v-card>
                <v-icon v-else color="primary" :size="55 * smallScreenScale">
                    {{ game.sport.icon }}
                </v-icon>
            </v-avatar>
        </template>
    </v-badge>
</template>

<script>
export default {
    name: "SportBadge",
    props: {
        game: Object,
    },
    computed: {
        smallScreenScale() {
            if (this.windowWidth > 412) return 1;
            return this.windowWidth / 412;
        },
        embed() {
            return this.$store.getters.getCurrentEmbed;
        },
    },
    data: () => ({
        windowWidth: null,
    }),
    mounted() {
        this.onResize();
    },

    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
        },
    },
};
</script>

<style scoped>
::v-deep i {
    font-style: normal;
}

::v-deep .v-badge__badge {
    border-radius: 50%;
    width: 75px;
    height: 75px;
}

.v-badge--avatar .v-badge__badge .v-avatar {
    margin-top: 7px;
    height: 61px !important;
    width: 61px !important;
    max-width: unset !important;
}

@media screen and (max-width: 412px) {
    ::v-deep .v-badge__badge {
        width: 18.2vw;
        height: 18.2vw;
    }

    .v-badge--avatar .v-badge__badge .v-avatar {
        margin-top: calc(7px * 100vw / 412);
        height: 14.8vw !important;
        width: 14.8vw !important;
    }
}
</style>
