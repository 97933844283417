var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('loading'):[_c('v-container',[_c('v-row',_vm._l((_vm.teams),function(team){return _c('v-col',{key:team.id,attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-card',{attrs:{"href":_vm.appUrl +
                            '/leagues/' +
                            team.league.slug.toLowerCase() +
                            '/teams/' +
                            team.text_id.toLowerCase() +
                            '/schedule'}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('a',[(
                                                !_vm.isNfl && team.logo_exists
                                            )?_c('v-img',{staticClass:"mx-auto d-print-none mb-2",attrs:{"content-class":"contain-paint","transition":"none","contain":"","max-width":"81","height":"54","src":_vm.logoSource(team),"alt":team.display_name_full +
                                                ' Logo'}}):_vm._e(),_vm._v(" "),_c('h2',{staticClass:"text-uppercase font-weight-bold team-name my-3"},[_vm._v("\n                                            "+_vm._s(_vm.isNfl
                                                    ? team.espn_name_team
                                                    : team.display_name_short)+"\n                                        ")])],1)])],1)],1)],1)],1)}),1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }