<template>
    <v-container>
        <h1 class="text-h4 mb-4">SEO Test Results</h1>

        <v-expansion-panels>
            <v-expansion-panel
                v-for="(route, index) in testRoutes"
                :key="index"
            >
                <v-expansion-panel-header>
                    <v-row no-gutters>
                        <v-col cols="8">
                            {{ route.name }}
                            <code class="ml-2">{{
                                JSON.stringify(route.params)
                            }}</code>
                        </v-col>
                        <v-col cols="4" class="text-right">
                            <v-icon
                                :color="getTestStatus(route).color"
                                class="mr-2"
                            >
                                {{ getTestStatus(route).icon }}
                            </v-icon>
                            {{ getTestStatus(route).text }}
                        </v-col>
                    </v-row>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                    <v-card flat>
                        <v-card-text>
                            <div
                                v-for="field in [
                                    'title',
                                    'description',
                                    'keywords',
                                ]"
                                :key="field"
                            >
                                <div
                                    class="text-subtitle-1 font-weight-bold mt-3 mb-2"
                                >
                                    {{
                                        field.charAt(0).toUpperCase() +
                                        field.slice(1)
                                    }}
                                </div>

                                <v-row>
                                    <v-col cols="6">
                                        <div class="caption grey--text">
                                            Expected:
                                        </div>
                                        <div>{{ route.expected[field] }}</div>
                                    </v-col>
                                    <v-col cols="6">
                                        <div class="caption grey--text">
                                            Actual:
                                        </div>
                                        <div
                                            :class="{
                                                'red--text':
                                                    getActualValue(
                                                        route,
                                                        field
                                                    ) !== route.expected[field],
                                                'green--text':
                                                    getActualValue(
                                                        route,
                                                        field
                                                    ) === route.expected[field],
                                            }"
                                        >
                                            {{ getActualValue(route, field) }}
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-divider
                                    v-if="field !== 'keywords'"
                                    class="my-2"
                                ></v-divider>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-card class="mt-4" outlined>
            <v-card-text>
                <div class="text-h6 mb-2">Summary</div>
                <v-row>
                    <v-col cols="4">
                        <v-stat-card
                            color="green"
                            :value="totalPassing"
                            title="Passing"
                            icon="mdi-check-circle"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-stat-card
                            color="red"
                            :value="totalFailing"
                            title="Failing"
                            icon="mdi-alert-circle"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-stat-card
                            color="blue"
                            :value="testRoutes.length"
                            title="Total Tests"
                            icon="mdi-format-list-checks"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { generateSEOTags } from "../router/afterEachHook";
import VStatCard from "../components/shared/VStatCard";

export default {
    name: "SeoTest",

    components: {
        VStatCard,
    },

    data() {
        return {
            testRoutes: window.initialState?.testRoutes || [],
            sportsData: window.initialState?.sports || null,
        };
    },

    computed: {
        totalPassing() {
            return this.testRoutes.filter(
                (route) => this.getTestStatus(route).icon === "mdi-check-circle"
            ).length;
        },
        totalFailing() {
            return this.testRoutes.length - this.totalPassing;
        },
    },

    methods: {
        getActualValue(route, field) {
            const seoTags = generateSEOTags(route, this.sportsData, false);
            let fieldValue = seoTags[field];

            return fieldValue;
        },

        getTestStatus(route) {
            const seoTags = generateSEOTags(route, this.sportsData, false);
            const fields = ["title", "description", "keywords"];

            const mismatches = fields.filter(
                (field) => seoTags[field] !== route.expected[field]
            );

            if (mismatches.length === 0) {
                return {
                    icon: "mdi-check-circle",
                    color: "success",
                    text: "All Passing",
                };
            }

            return {
                icon: "mdi-alert-circle",
                color: "error",
                text: `${mismatches.length} Failed`,
            };
        },
    },
};
</script>

<style scoped>
code {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 0.9em;
}
</style>
