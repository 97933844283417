<template>
    <div>
        <loading v-if="loading" />
        <template v-else>
            <v-data-table
                :headers="headers"
                :items="sortedTeams"
                :items-per-page="40"
                item-key="teamId"
                :item-class="rowClass"
                :expanded.sync="expanded"
                show-expand
                single-expand
                class="elevation-1"
                :sort-by="['location']"
                :sort-desc="[false]"
            >
                <template v-slot:item="{ item }">
                    <tr @click="toggleExpansion(item)">
                        <td>&nbsp;</td>
                        <td>
                            <h2>{{ item.location }} {{ item.nickname }}</h2>
                        </td>
                        <td>
                            <v-btn
                                text
                                class="pa-0"
                                color="primary"
                                @click.stop="toggleExpansion(item)"
                            >
                                View Depth Chart
                            </v-btn>
                        </td>
                    </tr>
                </template>
                <template v-slot:expanded-item="{ item }">
                    <td :colspan="headers.length + 1" class="expanded-row">
                        <v-simple-table class="ma-5">
                            <thead>
                                <tr>
                                    <th>Position</th>
                                    <th v-for="n in getMaxRank(item)" :key="n">
                                        {{ rankLabel(n) }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(position, index) in item.positions"
                                    :key="position.positionId"
                                    :class="{
                                        'odd-row': index % 2 === 0,
                                        'even-row': index % 2 === 1,
                                    }"
                                >
                                    <td>
                                        <span class="position-field">
                                            {{
                                                positionFullName(position.name)
                                            }}
                                        </span>
                                    </td>
                                    <td v-for="n in getMaxRank(item)" :key="n">
                                        <span
                                            v-if="getPlayerByRank(position, n)"
                                        >
                                            {{
                                                formatPlayerName(
                                                    getPlayerByRank(position, n)
                                                )
                                            }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </td>
                </template>
            </v-data-table>
        </template>
    </div>
</template>

<script>
import { GET_LEAGUE_DEPTH_CHARTS } from "../../store/actions.type";
import Loading from "../../components/shared/Loading";

export default {
    name: "DepthCharts",
    components: {
        Loading,
    },
    data() {
        return {
            loading: true,
            expanded: [],
            headers: [
                {
                    text: "Team",
                    align: "start",
                    sortable: false,
                    value: "location",
                },
                { text: "Depth Chart", value: "actions", sortable: false },
            ],
        };
    },
    computed: {
        statsData() {
            return this.$store.getters.currentStatsData;
        },
        league() {
            return this.statsData.league;
        },
        sortedTeams() {
            if (!this.league?.season?.teams) return [];
            return [...this.league.season.teams].sort((a, b) =>
                a.location.localeCompare(b.location)
            );
        },
    },
    methods: {
        rowClass(item) {
            return this.expanded.includes(item) ? "expanded-row" : "";
        },
        positionFullName(position) {
            switch (this.$route.params.league) {
                case "nfl":
                    return this.nflPositionFullName(position);
                default:
                    return position;
            }
        },
        nflPositionFullName(position) {
            switch (position) {
                case "SLB":
                    return "Strongside Linebacker";
                case "RDE":
                    return "Right Defensive End";
                case "RDT":
                    return "Right Defensive Tackle";
                case "WLB":
                    return "Weakside Linebacker";
                case "MLB":
                    return "Middle Linebacker";
                case "LDE":
                    return "Left Defensive End";
                case "LDT":
                    return "Left Defensive Tackle";
                case "QB":
                    return "Quarterback";
                case "RB":
                    return "Running Back";
                case "FB":
                    return "Fullback";
                case "WR":
                    return "Wide Receiver";
                case "TE":
                    return "Tight End";
                case "C":
                    return "Center";
                case "G":
                    return "Guard";
                case "T":
                    return "Tackle";
                case "DT":
                    return "Defensive Tackle";
                case "DE":
                    return "Defensive End";
                case "LB":
                    return "Linebacker";
                case "CB":
                    return "Cornerback";
                case "S":
                    return "Safety";
                case "K":
                    return "Kicker";
                case "P":
                    return "Punter";
                case "LS":
                    return "Long Snapper";
                case "KR":
                    return "Kick Returner";
                case "LCB":
                    return "Left Cornerback";
                case "LILB":
                    return "Left Inside Linebacker";
                case "LOG":
                    return "Left Offensive Guard";
                case "LOLB":
                    return "Left Outside Linebacker";
                case "LOT":
                    return "Left Offensive Tackle";
                case "NT":
                    return "Nose Tackle";
                case "PR":
                    return "Punt Returner";
                case "FS":
                    return "Free Safety";
                case "H":
                    return "Holder";
                case "K-FG":
                    return "Field Goal Kicker";
                case "K-KO":
                    return "Kickoff Kicker";
                case "RCB":
                    return "Right Cornerback";
                case "RILB":
                    return "Right Inside Linebacker";
                case "ROG":
                    return "Right Offensive Guard";
                case "ROLB":
                    return "Right Outside Linebacker";
                case "ROT":
                    return "Right Offensive Tackle";
                case "SS":
                    return "Strong Safety";
                case "WR1":
                    return "Wide Receiver 1";
                case "WR2":
                    return "Wide Receiver 2";
                case "WR3":
                    return "Wide Receiver 3";
                default:
                    return position;
            }
        },
        toggleExpansion(item) {
            const index = this.expanded.indexOf(item);
            if (index > -1) {
                this.expanded.splice(index, 1);
            } else {
                this.expanded = [item];
            }
        },
        getMaxRank(team) {
            if (!team.positions) return 0;
            return team.positions.reduce((max, pos) => {
                const length = pos.depthChart ? pos.depthChart.length : 0;
                return length > max ? length : max;
            }, 0);
        },
        getPlayerByRank(position, rank) {
            if (!position.depthChart) return null;
            return position.depthChart.find((entry) => entry.depth === rank);
        },
        formatPlayerName(entry) {
            return `${entry.player.firstName} ${entry.player.lastName}`;
        },
        rankLabel(rank) {
            const rankLabels = [
                "Starter",
                "First",
                "Second",
                "Third",
                "Fourth",
                "Fifth",
                "Sixth",
                "Seventh",
                "Eighth",
                "Ninth",
                "Tenth",
            ];
            return rankLabels[rank - 1] || `Rank ${rank}`;
        },
    },
    mounted() {
        this.$store
            .dispatch(GET_LEAGUE_DEPTH_CHARTS, this.$route.params.league)
            .then(() => {
                this.loading = false;
            });
    },
};
</script>

<style scoped>
/* Alternating row colors */
.odd-row {
    background-color: #f9f9f9 !important;
}
.even-row {
    background-color: #fff !important;
}

/* NEW: Highlight style for expanded row */
.expanded-row {
    background-color: #cce5ff !important;
}

/* Ensuring left aligned columns in the simple table */
.v-simple-table th,
.v-simple-table td {
    text-align: left !important;
}

::v-deep .datatable-row:nth-child(odd) {
    background-color: #f9f9f9 !important;
}
::v-deep .datatable-row:nth-child(even) {
    background-color: #b3d1f2 !important;
}

/* NEW: Styling for the position field */
.position-field {
    font-weight: bold;
    color: #2c3e50;
    background-color: #ecf0f1;
    padding: 4px 8px;
    border-radius: 4px;
}
</style>
