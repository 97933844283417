<template>
    <v-row no-gutters class="px-5" align="center">
        <v-col class="mt-md-11 mt-8 py-0 pr-0">
            <template v-if="!isMobile">
                <time-display v-if="game.game_status_id == 1" :game="game" />
                <h3 v-else>
                    {{
                        game.game_status_id == 2 && game.game_status_reason
                            ? game.game_status_reason
                            : game.game_status.name
                    }}
                </h3>
            </template>
            <v-row no-gutters class="mt-1 mb-4">
                <v-col
                    cols="auto"
                    v-for="(showing, showing_index) in game.showings"
                    :key="showing_index"
                    @click="liteChannel(showing_index)"
                >
                    <channel :channel="showing.channel" />
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="auto" v-if="game.league.has_logo">
            <v-card
                :href="
                    !isEmbed && game.league.show_season_schedule
                        ? `${appUrl}/leagues/${game.league.slug.toLowerCase()}/schedule`
                        : ''
                "
                flat
                tile
            >
                <v-img
                    class="mt-5"
                    contain
                    content-class="contain-paint"
                    transition="none"
                    :src="`${appUrl}/img/logos/leagues/${game.league.slug}.svg`"
                    width="80"
                    height="80"
                    :alt="game.league.name + ' Logo'"
                />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import TimeDisplay from "./Shared/TimeDisplay";
import Channel from "./Channel";

export default {
    name: "GameTitle",
    components: {
        TimeDisplay,
        Channel,
    },
    props: {
        game: Object,
    },
    methods: {
        liteChannel(index, scroll = true) {
            const panels = this.$parent.$refs.details.$refs.panels;
            let panel_index = this.isMobile ? 0 : 2;
            if (!panels.openPanels.includes(panel_index)) {
                panels.openPanels.push(panel_index);
            }
            setTimeout(() => {
                let enabled =
                    this.$parent.$refs.details.$refs.panels.$refs.showings[0].liteChannel(
                        index
                    );

                if (enabled && scroll)
                    this.$vuetify.goTo("#game-showings-" + this.game.id);
            }, 0);
        },
    },
    mounted() {
        if (
            this.game.showings.length == 1 &&
            this.game.showings[0].channel.number_major == 40012
        ) {
            this.liteChannel(0, false);
        }
    },
};
</script>

<style scoped>
::v-deep .v-badge__badge {
    border-radius: 50%;
    width: 75px;
    height: 75px;
}
.v-badge--avatar .v-badge__badge .v-avatar {
    margin-top: 7px;
    height: 61px !important;
    width: 61px !important;
    max-width: unset !important;
}
</style>
