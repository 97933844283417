var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.layout > 0)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{staticClass:"mx-1",attrs:{"no-gutters":"","align":"center","justify":"space-around"}},[_c('v-col',[_c('team',{attrs:{"team":_vm.game.away_team}})],1),_vm._v(" "),(
                    _vm.game.game_status_id > 1 &&
                    _vm.game.away_score !== null &&
                    _vm.game.home_score !== null
                )?_c('v-col',{staticClass:"mx-3",attrs:{"cols":"auto"}},[_c('div',{staticClass:"matchup"},[_vm._v("\n                    "+_vm._s(_vm.game.away_score)+" - "+_vm._s(_vm.game.home_score)+"\n                ")])]):_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"matchup"},[_vm._v("@")])]),_vm._v(" "),_c('v-col',[_c('team',{attrs:{"team":_vm.game.home_team}})],1)],1)],1)],1):(_vm.isMobile)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-simple-table',{staticClass:"mx-5"},[_c('tr',[_c('td',{attrs:{"width":"50"}},[_c('a',{attrs:{"href":!_vm.isEmbed && _vm.game.league.show_season_schedule
                                ? _vm.appUrl +
                                  '/leagues/' +
                                  _vm.game.away_team.league_slug.toLowerCase() +
                                  '/teams/' +
                                  _vm.game.away_team.text_id.toLowerCase() +
                                  '/schedule'
                                : false}},[(!_vm.isNfl && _vm.game.away_team.logo_exists)?_c('v-img',{staticClass:"d-print-none",attrs:{"transition":"none","content-class":"contain-paint","contain":"","height":"30","max-width":"45","src":_vm.appUrl +
                                '/img/logos/teams/' +
                                (_vm.game.away_team.master_team_id
                                    ? _vm.game.away_team.master_team_id
                                    : _vm.game.away_team.id) +
                                '.svg',"alt":_vm.game.away_team.name + ' Logo'}}):_vm._e()],1)]),_vm._v(" "),_c('td',{staticClass:"text-uppercase"},[(!_vm.isEmbed && _vm.game.league.show_season_schedule)?[_c('a',{attrs:{"href":_vm.appUrl +
                                '/leagues/' +
                                _vm.game.away_team.league_slug.toLowerCase() +
                                '/teams/' +
                                _vm.game.away_team.text_id.toLowerCase() +
                                '/schedule'}},[_vm._v("\n                            "+_vm._s(_vm.isNfl
                                    ? _vm.game.away_team.espn_name_team
                                    : _vm.game.away_team.display_name_short)+"\n                        ")])]:[_vm._v("\n                        "+_vm._s(_vm.isNfl
                                ? _vm.game.away_team.espn_name_team
                                : _vm.game.away_team.display_name_short)+"\n                    ")]],2),_vm._v(" "),_c('td',[(
                            _vm.game.game_status_id > 1 &&
                            _vm.game.away_score !== null &&
                            _vm.game.home_score !== null
                        )?_c('div',[_vm._v("\n                        "+_vm._s(_vm.game.away_score)+"\n                    ")]):_vm._e()]),_vm._v(" "),_c('td',[(_vm.game.game_status_id == 1)?_c('time-display',{attrs:{"game":_vm.game}}):_c('div',[_vm._v("\n                        "+_vm._s(_vm.game.game_status_id != 4 &&
                            _vm.game.game_status_reason
                                ? _vm.game.game_status_reason
                                : _vm.game.game_status.name)+"\n                    ")])],1)]),_vm._v(" "),_c('tr',[_c('td',{attrs:{"width":"50"}},[_c('a',{attrs:{"href":!_vm.isEmbed && _vm.game.league.show_season_schedule
                                ? _vm.appUrl +
                                  '/leagues/' +
                                  _vm.game.home_team.league_slug.toLowerCase() +
                                  '/teams/' +
                                  _vm.game.home_team.text_id.toLowerCase() +
                                  '/schedule'
                                : false}},[(!_vm.isNfl && _vm.game.home_team.logo_exists)?_c('v-img',{staticClass:"d-print-none",attrs:{"content-class":"contain-paint","transition":"none","contain":"","height":"30","max-width":"45","src":_vm.appUrl +
                                '/img/logos/teams/' +
                                (_vm.game.home_team.master_team_id
                                    ? _vm.game.home_team.master_team_id
                                    : _vm.game.home_team.id) +
                                '.svg',"alt":_vm.game.home_team.name + ' Logo'}}):_vm._e()],1)]),_vm._v(" "),_c('td',{staticClass:"text-uppercase"},[(!_vm.isEmbed && _vm.game.league.show_season_schedule)?[_c('a',{attrs:{"href":_vm.appUrl +
                                '/leagues/' +
                                _vm.game.home_team.league_slug.toLowerCase() +
                                '/teams/' +
                                _vm.game.home_team.text_id.toLowerCase() +
                                '/schedule'}},[_vm._v("\n                            "+_vm._s(_vm.isNfl
                                    ? _vm.game.home_team.espn_name_team
                                    : _vm.game.home_team.display_name_short)+"\n                        ")])]:[_vm._v("\n                        "+_vm._s(_vm.isNfl
                                ? _vm.game.home_team.espn_name_team
                                : _vm.game.home_team.display_name_short)+"\n                    ")]],2),_vm._v(" "),_c('td',[(
                            _vm.game.game_status_id > 1 &&
                            _vm.game.away_score !== null &&
                            _vm.game.home_score !== null
                        )?_c('div',[_vm._v("\n                        "+_vm._s(_vm.game.home_score)+"\n                    ")]):_vm._e()]),_vm._v(" "),_c('td')])])],1)],1):_c('v-row',{staticClass:"pt-2",attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('team',{attrs:{"team":_vm.game.away_team}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[(
                _vm.game.game_status_id > 1 &&
                _vm.game.status_away_score !== null &&
                _vm.game.status_home_score !== null
            )?_c('score',{attrs:{"game":_vm.game}}):_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('div',{staticClass:"matchup"},[_vm._v("@")])])],1)],1),_vm._v(" "),_c('v-col',{staticClass:"text-center"},[_c('team',{attrs:{"team":_vm.game.home_team}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }