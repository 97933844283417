<template>
    <v-card :color="color" dark>
        <v-card-text>
            <v-row align="center" no-gutters>
                <v-col>
                    <div class="text-h4">{{ value }}</div>
                    <div class="text-subtitle-2">{{ title }}</div>
                </v-col>
                <v-col cols="auto">
                    <v-icon large>{{ icon }}</v-icon>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "VStatCard",
    props: {
        color: {
            type: String,
            default: "primary",
        },
        value: {
            type: [Number, String],
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
    },
};
</script>
