<template>
    <div>
        <v-row>
            <v-col cols="12">
                Positions:
                <v-tooltip
                    v-for="(position, index) in positions"
                    :key="index"
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            v-bind="attrs"
                            v-on="on"
                            @click="selectedPosition = position.abbreviation"
                            mandatory
                            class="ma-2"
                            :color="
                                position.abbreviation === selectedPosition
                                    ? 'primary'
                                    : ''
                            "
                        >
                            {{ position.abbreviation }}
                        </v-chip>
                    </template>
                    <span>{{ position.name }}</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <!-- Player Data Table -->
        <v-data-table
            :headers="filteredHeaders"
            :items="formattedPlayers"
            :item-class="rowClass"
            :search="search"
            mobile-breakpoint="1600"
        >
            <template v-slot:top>
                <v-text-field
                    prepend-icon="mdi-magnify"
                    v-model="search"
                    label="Search"
                    class="mx-4"
                />
            </template>
            <template v-slot:header="props">
                <thead>
                    <tr v-if="$vuetify.breakpoint.width >= 1600">
                        <th :colspan="isTeamView ? 9 : 10" class="text-center">
                            <v-card color="grey lighten-3">Player</v-card>
                        </th>
                        <th colspan="4" class="text-center">
                            <v-card color="grey lighten-3">Status</v-card>
                        </th>
                        <th colspan="4" class="text-center">
                            <v-card color="grey lighten-3">Lists</v-card>
                        </th>

                        <th colspan="3" class="text-center">
                            <v-card color="grey lighten-3">Draft</v-card>
                        </th>
                    </tr>
                </thead>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: "MLB",
    props: {
        league: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            search: "",
            selectedPosition: "All",
            playerHeaders: [
                { text: "Name", value: "name" },
                { text: "Team", value: "team", hideForTeamView: true },
                { text: "#", value: "uniform", align: "center" },
                { text: "Throwing", value: "throwingHand", align: "center" },
                { text: "Batting", value: "batSide", align: "center" },
                { text: "Height", value: "height" },
                { text: "Weight", value: "weight", align: "center" },
                // { text: 'B-Day', value: 'birthDate' },
                // { text: 'B-Place', value: 'birthPlace' },
                { text: "Nationality", value: "nationality" },
                { text: "Ethnicity", value: "ethnicity" },
                { text: "Positions", value: "positions" },
                // { text: 'Experience', value: 'experience' },
                // { text: 'Hometown', value: 'hometown' },

                { text: "Inj.", value: "isInjured", align: "center" },
                { text: "Pat.", value: "isPaternityList", align: "center" },
                { text: "Rook.", value: "isRookie", align: "center" },
                { text: "Susp.", value: "isSuspended", align: "center" },

                {
                    text: "Bereavement",
                    value: "isBereavementList",
                    align: "center",
                },
                {
                    text: "Restricted",
                    value: "isRestrictedList",
                    align: "center",
                },
                {
                    text: "Postseason",
                    value: "isPostseasonRoster",
                    align: "center",
                },
                { text: "40-Man", value: "isFortyManRoster", align: "center" },

                { text: "Year", value: "draftYear" },
                { text: "Team", value: "draftTeam" },
                { text: "Type", value: "draftType" },
            ],
        };
    },
    computed: {
        isTeamView() {
            return this.$route.params.team && this.$route.params.team !== "0";
        },
        positions() {
            const uniqueAbbreviations = new Set(
                this.league.players.flatMap((p) =>
                    p.positions.map((pos) => pos.abbreviation)
                )
            );
            const positions = Array.from(uniqueAbbreviations).map(
                (abbreviation) => {
                    return this.league.players
                        .flatMap((p) => p.positions)
                        .find((pos) => pos.abbreviation === abbreviation);
                }
            );
            positions.unshift({ abbreviation: "All", name: "All Positions" });
            return positions;
        },
        filteredHeaders() {
            if (this.isTeamView) {
                return this.playerHeaders.filter(
                    (header) => !header.hideForTeamView
                );
            }
            return this.playerHeaders;
        },
        filteredPlayers() {
            if (this.selectedPosition === "All") {
                return this.league.players;
            }
            return this.league.players.filter((player) =>
                player.positions.some(
                    (pos) => pos.abbreviation === this.selectedPosition
                )
            );
        },
        formattedPlayers() {
            // Sort players by last name (SortPlayers)
            const sortedPlayers = this.filteredPlayers
                .slice()
                .sort((a, b) => a.lastName.localeCompare(b.lastName));
            return sortedPlayers.map((player) => ({
                // Display Last Name before First Name (NameOrder)
                name: `${player.lastName} ${player.firstName}`,
                throwingHand: player.throwingHand.name,
                batSide: player.batSide.name,
                isBereavementList: player.isBereavementList ? "✔️" : "❌",
                isRestrictedList: player.isRestrictedList ? "✔️" : "❌",
                isPostseasonRoster: player.isPostseasonRoster ? "✔️" : "❌",
                isFortyManRoster: player.isFortyManRoster ? "✔️" : "❌",
                isInjured: player.isInjured ? "Yes" : "No",
                isPaternityList: player.isPaternityList ? "✔️" : "❌",
                isRookie: player.isRookie ? "✔️" : "❌",
                isSuspended: player.isSuspended ? "✔️" : "❌",
                draftYear: player.draft ? player.draft.year : "",
                draftTeam:
                    player.draft && player.draft.team
                        ? `${player.draft.team.nickname}`
                        : "",
                draftType: player.draft ? player.draft.draftType.name : "",
                team: player.team ? `${player.team.nickname}` : "",
                height: `${player.height.inches}in`,
                weight: `${player.weight.pounds}lbs`,
                age: moment().diff(player.birth.birthDate.full, "years"),
                nationality: player.nationality.abbreviation,
                ethnicity: player.ethnicity ? player.ethnicity.name : "",
                positions: player.positions.map((p) => p.name).join(", "),
                uniform: player.uniform,
            }));
        },
    },
    methods: {
        positionsString(positions) {
            return positions.map((p) => p.abbreviation).join(", ");
        },
    },
};
</script>

<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    white-space: nowrap !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th > span {
    white-space: nowrap !important;
}

.v-text-field >>> .v-text-field__slot {
    padding-top: 4px !important;
    height: 66px;
}

.v-text-field--outlined >>> .v-label {
    font-size: 1.5em;
    font-weight: bold;
    text-transform: uppercase;
}

.v-text-field--outlined >>> input {
    font-size: 1.2em;
}

.v-text-field--outlined >>> fieldset {
    border-width: 3px;
}
</style>

<style scoped>
.search-field {
    padding-top: 20px;
}

.search-field >>> .v-label {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.search-field >>> input {
    font-size: 20px;
}

.search-field >>> .v-icon {
    font-size: 40px;
}
::v-deep .datatable-row:nth-child(odd) {
    background-color: #f9f9f9 !important;
}
::v-deep .datatable-row:nth-child(even) {
    background-color: #b3d1f2 !important;
}
</style>
