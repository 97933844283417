<template>
    <div>
        <loading v-if="loading" />
        <template v-else>
            <v-card-title class="text-center">
                <h2>{{ league.season.name }}</h2></v-card-title
            >
            <v-row>
                <v-col
                    v-for="conference in sortedConferences"
                    :key="conference.conferenceId"
                    cols="12"
                    md="6"
                >
                    <v-row>
                        <v-col
                            v-for="division in conference.divisions"
                            :key="division.divisionId"
                            cols="12"
                            sm="6"
                        >
                            <v-card>
                                <v-card-title>
                                    <h3>{{ division.name }}</h3>
                                </v-card-title>
                                <v-card-text>
                                    <div
                                        v-for="team in division.teams"
                                        :key="team.teamId"
                                    >
                                        <h4>
                                            {{ team.location }}
                                            {{ team.nickname }}
                                        </h4>
                                        <p>
                                            Wins: {{ team.record.wins }},
                                            Losses: {{ team.record.losses }}
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import { GET_LEAGUE_STANDINGS } from "../../store/actions.type";
import Loading from "../../components/shared/Loading";

export default {
    name: "Standings",
    components: {
        Loading,
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        statsData() {
            return this.$store.getters.currentStatsData;
        },
        league() {
            return this.statsData.league;
        },
        sortedConferences() {
            if (
                !this.league ||
                !this.league.season ||
                !this.league.season.eventType ||
                !this.league.season.eventType[0].conferences
            ) {
                return [];
            }

            return this.league.season.eventType[0].conferences.map(
                (conference) => {
                    const sortedDivisions = (conference.divisions || [])
                        .slice()
                        .sort((a, b) => {
                            return a.name.localeCompare(b.name);
                        });

                    sortedDivisions.forEach((division) => {
                        division.teams = (division.teams || [])
                            .slice()
                            .sort((a, b) => {
                                const fullNameA = (
                                    (a.location || "") +
                                    " " +
                                    (a.nickname || "")
                                )
                                    .trim()
                                    .toLowerCase();
                                const fullNameB = (
                                    (b.location || "") +
                                    " " +
                                    (b.nickname || "")
                                )
                                    .trim()
                                    .toLowerCase();
                                return fullNameA.localeCompare(fullNameB);
                            });
                    });

                    return { ...conference, divisions: sortedDivisions };
                }
            );
        },
    },
    mounted() {
        this.$store
            .dispatch(GET_LEAGUE_STANDINGS, this.$route.params.league)
            .then(() => {
                this.loading = false;
            });
    },
};
</script>
