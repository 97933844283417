<template>
    <div>
        <v-row>
            <v-col cols="12">
                Positions:
                <v-chip
                    v-for="(position, index) in positions"
                    :key="index"
                    @click="selectPosition(position)"
                    mandatory
                    class="ma-2"
                    :color="position === selectedPosition ? 'primary' : ''"
                >
                    {{ position }}
                </v-chip>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="filteredFantasyProjections"
            :item-class="rowClass"
            item-key="playerId"
            show-expand
            single-expand
            :expanded.sync="expanded"
            class="elevation-1"
            :search="search"
            :loading="loading"
        >
            <template v-slot:top>
                <v-text-field v-model="search" label="Search" class="mx-4" />
            </template>

            <!--Offensive-->

            <template v-slot:header="props">
                <thead v-if="selectedPosition != 'DEF'">
                    <tr>
                        <th colspan="5" class="text-center">
                            <v-card color="grey lighten-3">Player</v-card>
                        </th>
                        <template v-if="selectedPosition == 'K'">
                            <th colspan="2"></th>
                            <th colspan="11" class="text-center">
                                <v-card color="grey lighten-3"
                                    >Field Goals</v-card
                                >
                            </th>
                        </template>
                        <template v-else>
                            <th colspan="4"></th>
                            <th colspan="3" class="text-center">
                                <v-card color="grey lighten-3">Passing</v-card>
                            </th>
                            <th colspan="3" class="text-center">
                                <v-card color="grey lighten-3">Rushes</v-card>
                            </th>
                            <th colspan="1"></th>
                        </template>
                    </tr>
                </thead>
            </template>

            <template v-slot:item.completions="{ item }">
                {{
                    item.completions && !isNaN(Number(item.completions))
                        ? Number(item.completions).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.attempts="{ item }">
                {{
                    item.attempts && !isNaN(Number(item.attempts))
                        ? Number(item.attempts).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.passYards="{ item }">
                {{
                    item.passYards && !isNaN(Number(item.passYards))
                        ? Number(item.passYards).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.passTouchdowns="{ item }">
                {{
                    item.passTouchdowns && !isNaN(Number(item.passTouchdowns))
                        ? Number(item.passTouchdowns).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.rushes="{ item }">
                {{
                    item.rushes && !isNaN(Number(item.rushes))
                        ? Number(item.rushes).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.rushYards="{ item }">
                {{
                    item.rushYards && !isNaN(Number(item.rushYards))
                        ? Number(item.rushYards).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.rushTouchdowns="{ item }">
                {{
                    item.rushTouchdowns && !isNaN(Number(item.rushTouchdowns))
                        ? Number(item.rushTouchdowns).toFixed(2)
                        : "-"
                }}
            </template>

            <!-- Kickers -->
            <template v-slot:item.fieldGoalsAttempted="{ item }">
                {{
                    item.fieldGoalsAttempted &&
                    !isNaN(Number(item.fieldGoalsAttempted))
                        ? Number(item.fieldGoalsAttempted).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.fieldGoalsMade="{ item }">
                {{
                    item.fieldGoalsMade && !isNaN(Number(item.fieldGoalsMade))
                        ? Number(item.fieldGoalsMade).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.fieldGoalPercentage="{ item }">
                {{
                    item.fieldGoalPercentage &&
                    !isNaN(Number(item.fieldGoalPercentage))
                        ? Number(item.fieldGoalPercentage).toFixed(2) * 100
                        : "-"
                }}
            </template>

            <template v-slot:item.fieldGoalsMade19="{ item }">
                {{
                    item.fieldGoalsMade19 &&
                    !isNaN(Number(item.fieldGoalsMade19))
                        ? Number(item.fieldGoalsMade19).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.fieldGoalsMade29="{ item }">
                {{
                    item.fieldGoalsMade29 &&
                    !isNaN(Number(item.fieldGoalsMade29))
                        ? Number(item.fieldGoalsMade29).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.fieldGoalsMade39="{ item }">
                {{
                    item.fieldGoalsMade39 &&
                    !isNaN(Number(item.fieldGoalsMade39))
                        ? Number(item.fieldGoalsMade39).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.fieldGoalsMade49="{ item }">
                {{
                    item.fieldGoalsMade49 &&
                    !isNaN(Number(item.fieldGoalsMade49))
                        ? Number(item.fieldGoalsMade49).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.fieldGoalsMade50="{ item }">
                {{
                    item.fieldGoalsMade50 &&
                    !isNaN(Number(item.fieldGoalsMade50))
                        ? Number(item.fieldGoalsMade50).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.extraPointsAttempted="{ item }">
                {{
                    item.extraPointsAttempted &&
                    !isNaN(Number(item.extraPointsAttempted))
                        ? Number(item.extraPointsAttempted).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.extraPointsMade="{ item }">
                {{
                    item.extraPointsMade && !isNaN(Number(item.extraPointsMade))
                        ? Number(item.extraPointsMade).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.extraPointPercentage="{ item }">
                {{
                    item.extraPointPercentage &&
                    !isNaN(Number(item.extraPointPercentage))
                        ? Number(item.extraPointPercentage).toFixed(2) * 100
                        : "-"
                }}
            </template>

            <!--Defensive-->

            <template v-slot:item.pointsAgainst="{ item }">
                {{
                    item.pointsAgainst && !isNaN(Number(item.pointsAgainst))
                        ? Number(item.pointsAgainst).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.sacks="{ item }">
                {{
                    item.sacks && !isNaN(Number(item.sacks))
                        ? Number(item.sacks).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.fumblesRecovered="{ item }">
                {{
                    item.fumblesRecovered &&
                    !isNaN(Number(item.fumblesRecovered))
                        ? Number(item.fumblesRecovered).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.safety="{ item }">
                {{
                    item.safety && !isNaN(Number(item.safety))
                        ? Number(item.safety).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.defensiveTouchdowns="{ item }">
                {{
                    item.defensiveTouchdowns &&
                    !isNaN(Number(item.defensiveTouchdowns))
                        ? Number(item.defensiveTouchdowns).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.puntReturnTouchdowns="{ item }">
                {{
                    item.puntReturnTouchdowns &&
                    !isNaN(Number(item.puntReturnTouchdowns))
                        ? Number(item.puntReturnTouchdowns).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.kickReturnTouchdowns="{ item }">
                {{
                    item.kickReturnTouchdowns &&
                    !isNaN(Number(item.kickReturnTouchdowns))
                        ? Number(item.kickReturnTouchdowns).toFixed(2)
                        : "-"
                }}
            </template>

            <!-- Shared -->
            <template v-slot:item.interceptions="{ item }">
                {{
                    item.interceptions && !isNaN(Number(item.interceptions))
                        ? Number(item.interceptions).toFixed(2)
                        : "-"
                }}
            </template>

            <template v-slot:item.actions="{ item }">
                <v-btn text color="primary" @click="toggleExpansion(item)">
                    View
                </v-btn>
            </template>
            <template v-slot:expanded-item="{ item }">
                <td :colspan="headers.length">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Name</th>
                                                <th class="text-left">
                                                    Points
                                                </th>
                                                <th class="text-left">Rank</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="projection in item.fantasyProjections"
                                                :key="projection.siteId"
                                            >
                                                <td>{{ projection.name }}</td>
                                                <td>
                                                    {{
                                                        Math.round(
                                                            projection.points
                                                        )
                                                    }}
                                                    Points
                                                </td>
                                                <td>
                                                    Rank: {{ projection.rank }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: "NFL",
    data() {
        return {
            loading: true,
            search: "",
            selectedPlayer: null,
            selectedPosition: "All Offense",
            expanded: [],
            positions: ["All Offense", "QB", "RB", "WR", "TE", "K", "DEF"],
            offensiveFantasyProjections: [],
            defensiveFantasyProjections: [],
            offensiveHeaders: [
                { text: "First", value: "firstName" },
                { text: "Last", value: "lastName" },
                { text: "Position", value: "position" },
                { text: "Team", value: "team" },
                { text: "GP", value: "gamesPlayed" },
                { text: "Bye", value: "byeWeek" },
                { text: "Completions", value: "completions", align: "center" },
                { text: "Attempts", value: "attempts", align: "center" },
                { text: "Yards", value: "passYards", align: "center" },
                { text: "TDs", value: "passTouchdowns", align: "center" },
                { text: "INTs", value: "interceptions", align: "center" },
                { text: "#", value: "rushes", align: "center" },
                { text: "Yards", value: "rushYards", align: "center" },
                { text: "TDs", value: "rushTouchdowns", align: "center" },
                // Add more headers for additional stats as needed
                { text: "Projections", value: "actions", sortable: false },
            ],
            kHeaders: [
                { text: "First", value: "firstName" },
                { text: "Last", value: "lastName" },
                { text: "Position", value: "position" },
                { text: "Team", value: "team" },
                { text: "GP", value: "gamesPlayed" },
                { text: "Bye", value: "byeWeek" },
                {
                    text: "Attempt",
                    value: "fieldGoalsAttempted",
                    align: "center",
                },
                { text: "Made", value: "fieldGoalsMade", align: "center" },
                { text: "%", value: "fieldGoalPercentage", align: "center" },
                { text: "0-19", value: "fieldGoalsMade19", align: "center" },
                { text: "20-29", value: "fieldGoalsMade29", align: "center" },
                { text: "30-39", value: "fieldGoalsMade39", align: "center" },
                { text: "40-49", value: "fieldGoalsMade49", align: "center" },
                { text: "50+", value: "fieldGoalsMade50", align: "center" },
                { text: "XPA", value: "extraPointsAttempted", align: "center" },
                { text: "XPM", value: "extraPointsMade", align: "center" },
                { text: "XP%", value: "extraPointPercentage", align: "center" },
            ],
            defensiveHeaders: [
                { text: "Team", value: "team" },
                { text: "GP", value: "gamesPlayed" },
                { text: "Bye", value: "byeWeek" },
                { text: "Points Against", value: "pointsAgainst" },
                { text: "Sacks", value: "sacks" },
                { text: "INTs", value: "interceptions" },
                { text: "Fumbles Recovered", value: "fumblesRecovered" },
                { text: "Safety", value: "safety" },
                { text: "Defensive TDs", value: "defensiveTouchdowns" },
                { text: "Punt Return TDs", value: "puntReturnTouchdowns" },
                { text: "Kick Return TDs", value: "kickReturnTouchdowns" },
                // Add more headers for additional stats as needed
                { text: "Projections", value: "actions", sortable: false },
            ],
        };
    },
    computed: {
        statsData() {
            return this.$store.getters.currentStatsData;
        },
        filteredFantasyProjections() {
            if (this.selectedPosition === "DEF") {
                return this.defensiveFantasyProjections;
            }

            return this.offensiveFantasyProjections.filter((projection) => {
                if (this.selectedPosition === "All Offense") {
                    return (
                        projection.position === "QB" ||
                        projection.position === "RB" ||
                        projection.position === "WR" ||
                        projection.position === "TE"
                    );
                }
                return projection.position === this.selectedPosition;
            });
        },
        headers() {
            if (this.selectedPosition === "DEF") {
                return this.defensiveHeaders;
            }
            if (this.selectedPosition === "K") {
                return this.kHeaders;
            }
            return this.offensiveHeaders;
        },
    },
    methods: {
        toggleExpansion(item) {
            if (this.expanded.length > 0 && this.expanded[0] === item) {
                this.expanded = [];
            } else {
                this.expanded = [item];
            }
        },
        selectPosition(position) {
            this.selectedPlayer = null; // Clear the selected player
            this.selectedPosition = position;
        },
        parseFantasyProjections() {
            const data = this.statsData;

            // Loop through each eventType and grab the offensiveFantasyProjections
            for (const eventType of data.league.season.eventType) {
                for (const projection of eventType.fantasyProjections
                    .offensiveProjections) {
                    const flatData = {
                        playerId: projection.player.playerId,
                        firstName: projection.player.firstName,
                        lastName: projection.player.lastName,
                        position: projection.position,
                        team: projection.team.nickname,
                        gamesPlayed: projection.gamesPlayed,
                        byeWeek: projection.byeWeek,
                        completions: projection.completions,
                        attempts: projection.attempts,
                        passYards: projection.passYards,
                        passTouchdowns: projection.passTouchdowns,
                        interceptions: projection.interceptions,
                        rushes: projection.rushes,
                        rushYards: projection.rushYards,
                        rushTouchdowns: projection.rushTouchdowns,
                        fantasyProjections: projection.fantasyProjections,
                        //Kickers
                        fieldGoalsAttempted: projection.fieldGoalsAttempted,
                        fieldGoalsMade: projection.fieldGoalsMade,
                        fieldGoalPercentage:
                            projection.fieldGoalsMade /
                            projection.fieldGoalsAttempted,
                        fieldGoalsMade19: projection.fieldGoalsMade19,
                        fieldGoalsMade29: projection.fieldGoalsMade29,
                        fieldGoalsMade39: projection.fieldGoalsMade39,
                        fieldGoalsMade49: projection.fieldGoalsMade49,
                        fieldGoalsMade50: projection.fieldGoalsMade50,
                        extraPointsAttempted: projection.extraPointsAttempted,
                        extraPointsMade: projection.extraPointsMade,
                        extraPointPercentage:
                            projection.extraPointsMade /
                            projection.extraPointsAttempted,
                    };
                    this.offensiveFantasyProjections.push(flatData);
                }
            }

            for (const eventType of data.league.season.eventType) {
                for (const projection of eventType.fantasyProjections
                    .defensiveProjections) {
                    const flatData = {
                        teamId: projection.team.teamId,
                        location: projection.team.location,
                        nickname: projection.team.nickname,
                        position: projection.position,
                        team: projection.team.nickname,
                        gamesPlayed: 16,
                        byeWeek: projection.byeWeek,
                        pointsAgainst: projection.pointsAgainst,
                        sacks: projection.sacks,
                        interceptions: projection.interceptions,
                        fumblesRecovered: projection.fumblesRecovered,
                        safety: projection.safety,
                        defensiveTouchdowns: projection.defensiveTouchdowns,
                        puntReturnTouchdowns: projection.puntReturnTouchdowns,
                        kickReturnTouchdowns: projection.kickReturnTouchdowns,
                        fantasyProjections: projection.fantasyProjections,
                    };
                    this.defensiveFantasyProjections.push(flatData);
                }
            }

            this.loading = false;
        },
    },
    mounted() {
        this.parseFantasyProjections();
    },
};
</script>

<style scoped>
.search-field {
    padding-top: 20px;
}

.search-field >>> .v-label {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.search-field >>> input {
    font-size: 20px;
}

.search-field >>> .v-icon {
    font-size: 40px;
}
::v-deep .datatable-row:nth-child(odd) {
    background-color: #f9f9f9 !important;
}
::v-deep .datatable-row:nth-child(even) {
    background-color: #b3d1f2 !important;
}
::v-deep tr {
    background-color: #f9f9f9 !important;
}
</style>
