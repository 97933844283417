import Vue from 'vue';

// Constants moved to top level for reusability
const SEO_CONSTANTS = {
    DEFAULT_TITLE: 'Sports TV Schedule & Channels',
    DEFAULT_POSTFIX: ' | DIRECTV',
    DEFAULT_DESCRIPTION: 'Sports Schedules for NFL, Soccer, NHL, NBA, World Cup, MLB, Cricket, MLS, NCAA, All Sports on DIRECTV',
    DEFAULT_KEYWORDS: 'NFL, Soccer, Sports Schedule, Sports, NHL, NBA, World Cup, MLB, Cricket, Leagues, MLS, NCAA, All Sports',
    ROUTE_SETTINGS: window.initialState?.routes || {},
    TEST_ROUTES: window.initialState?.testRoutes || []
};

// Helper functions
function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';
}

function toTitleCase(string) {
    if (!string) return '';
    return string
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

function getSportOrLeagueReplacement({ sport, league, leagueTitle }) {
    if (sport === 'all') {
        return 'Sports';
    }
    if (sport && !league) {
        return capitalizeFirstLetter(sport);
    }
    return leagueTitle || '';
}

function replaceTemplateStrings(text, data) {
    const { sport, league, leagueTitle, leagueTitleFull, team, leagueYear } = data;

    // First replace year-related placeholders
    let result = text
        .replace(/%LEAGUE_YEAR%/g, leagueYear || '')
        .replace(/%YEAR%/g, new Date().getFullYear().toString());

    // Then handle sport/league replacements with proper concatenation
    result = result
        .replace(/%SPORT%/g, sport ? capitalizeFirstLetter(sport) : '')
        .replace(/%LEAGUE%/g, leagueTitle || '')
        .replace(/%LEAGUE_FULL%/g, leagueTitleFull || '');

    // Handle team replacements
    result = result
        .replace(/%TEAM%/g, team ? toTitleCase(team) : '');

    // Handle sport_or_league last since it depends on other values
    const sportOrLeague = getSportOrLeagueReplacement({ sport, league, leagueTitle });

    result = result
        .replace(/%SPORT_OR_LEAGUE%/g, sportOrLeague);

    // Trim spaces and normalize whitespace
    result = result
        .replace(/\s+/g, ' ')  // Replace multiple spaces with single space
        .trim();               // Remove leading/trailing spaces

    return result;
}

// Main SEO generation logic
export function generateSEOTags(route, sportsData, isDebug = false) {
    const { DEFAULT_TITLE, DEFAULT_POSTFIX, DEFAULT_DESCRIPTION, DEFAULT_KEYWORDS, ROUTE_SETTINGS } = SEO_CONSTANTS;

    // Get route settings
    const routeName = route.name;
    const routeSetting = ROUTE_SETTINGS[routeName] || ROUTE_SETTINGS['home'];

    // Extract route parameters
    let routeSport = (route.params && route.params.sport) ? route.params.sport.toLowerCase() : false;
    let routeLeague = (route.params && route.params.league) ? route.params.league.toLowerCase() : false;
    let routeTeam = false;

    if (route.params && route.params.team) {
        routeTeam = route.params.team.toLowerCase();
        const teamNameParts = routeTeam.split('-');
        routeTeam = teamNameParts.slice(0, -1).join(' ');
    }

    let leagueTitle = '';
    let leagueTitleFull = '';
    let leagueDescription = '';
    let leagueKeywords = '';
    let leagueYear = '';
    let league = false;

    // Process league and sport combinations
    if (routeLeague) {
        if (routeSport === 'all' && routeLeague === 'all') {
            leagueTitle = 'Sports';
            leagueTitleFull = 'Sports';
        } else if (routeSport === 'custom' || routeLeague === 'custom') {
            if (routeSport !== 'all' && routeSport !== 'custom') {
                leagueTitle = `${capitalizeFirstLetter(routeSport)}`;
                leagueTitleFull = leagueTitle;
            } else {
                leagueTitle = 'Sports';
                leagueTitleFull = 'Sports';
            }
        } else if (routeSport !== 'all' && routeSport && routeLeague === 'all') {
            leagueTitle = capitalizeFirstLetter(routeSport);
            leagueTitleFull = leagueTitle;
        } else if (routeLeague !== 'all' && routeLeague !== 'custom') {
            // Find matching league and associated sport from sportsData
            const result = sportsData && sportsData.reduce((found, sport) => {
                if (found) return found;
                const matchingLeague = sport.leagues && sport.leagues.find(l => l.slug.toLowerCase() === routeLeague);
                return matchingLeague ? { league: matchingLeague, sportName: sport.title } : null;
            }, null);

            if (result) {
                league = result.league;
                const sportTitle = capitalizeFirstLetter(result.sportName);
                leagueTitle = league.show_sport_in_title ? `${league.title} ${sportTitle}` : `${league.title}`;
                leagueTitleFull = `${league.title} ${sportTitle}`;
                leagueDescription = league.description;
                leagueKeywords = league.keywords;
                leagueYear = league.year ? league.year : '';

                if (league.college) {
                    routeTeam += ` ${sportTitle}`;
                }
            }
        }
    }

    // Initialize main values
    let finalTitle = routeSetting.title || DEFAULT_TITLE;
    let finalDescription = leagueDescription || routeSetting.description || DEFAULT_DESCRIPTION;
    let finalKeywords = leagueKeywords || routeSetting.keywords || DEFAULT_KEYWORDS;
    let finalPostfix = routeSetting.postfix || DEFAULT_POSTFIX;

    // Calculate sportOrLeague substitution value
    let sportOrLeague = '';
    if (routeSport === 'all' || !routeSport) {
        sportOrLeague = '';
    } else if (routeSport && !routeLeague) {
        sportOrLeague = capitalizeFirstLetter(routeSport);
    } else {
        sportOrLeague = leagueTitle || '';
    }

    finalTitle = finalTitle + finalPostfix;

    if (routeName === 'home' || routeName === 'home_params' || routeName === 'league-schedule') {
        if (leagueDescription) {
            finalDescription = leagueDescription;
        }
        if (leagueKeywords) {
            finalKeywords = leagueKeywords;
        } else if (routeSport === 'all' || routeSport === 'custom') {
            finalKeywords = DEFAULT_KEYWORDS;
        }
    } else if (routeLeague && routeLeague !== 'all' && routeLeague !== 'custom' && league) {
        // Add year prefix if league has year
        leagueYear = league.year ? league.year : '';

        if (league[`title_seo_${routeName}`]) {
            finalTitle = leagueYear + league[`title_seo_${routeName}`];
        }

        finalDescription = league[`description_${routeName}`] || routeSetting.description;
        finalKeywords = league[`keywords_${routeName}`] || routeSetting.keywords;
    }

    // Process template strings in the title
    finalTitle = replaceTemplateStrings(finalTitle, {
        sport: capitalizeFirstLetter(routeSport),
        league: routeLeague,
        leagueTitle,
        leagueTitleFull,
        team: routeTeam,
        leagueYear
    });

    finalDescription = replaceTemplateStrings(finalDescription, {
        sport: capitalizeFirstLetter(routeSport),
        league: routeLeague,
        leagueTitle,
        leagueTitleFull,
        team: routeTeam,
        leagueYear
    });

    finalKeywords = replaceTemplateStrings(finalKeywords, {
        sport: capitalizeFirstLetter(routeSport),
        league: routeLeague,
        leagueTitle,
        leagueTitleFull,
        team: routeTeam,
        leagueYear
    });

    if (isDebug) {
        // Debug logging to match PHP SeoService
        console.group('SEO Debug Information');

        console.log('%cRoute Information', 'font-weight: bold; background: #e0e0e0; padding: 2px 5px;');
        console.table({
            'Current Route Name': route.name,
            'Route Path': route.path
        });

        console.log('%cDefault Route Template', 'font-weight: bold; background: #e0e0e0; padding: 2px 5px;');
        console.table(routeSetting);

        console.log('%cRoute Parameters', 'font-weight: bold; background: #e0e0e0; padding: 2px 5px;');
        console.table(route.params);

        console.log('%cBasic Parameters', 'font-weight: bold; background: #e0e0e0; padding: 2px 5px;');
        console.table({
            'Sport': routeSport || 'Not set',
            'League': routeLeague || 'Not set',
            'Team': routeTeam || 'Not set'
        });

        console.log('%cLeague Information', 'font-weight: bold; background: #e0e0e0; padding: 2px 5px;');
        if (league) {
            console.table({
                'Title': league.title,
                'Text ID': league.text_id,
                'Description': league.description || 'Not set',
                'Show Sport In Title': league.show_sport_in_title ? 'Yes' : 'No'
            });

            console.log(`%cRoute-Specific League SEO (${route.name})`, 'font-weight: bold; background: #e0e0e0; padding: 2px 5px;');
            console.table({
                'Title SEO': league[`title_seo_${route.name}`] || 'Not set',
                'Description': league[`description_${route.name}`] || 'Not set',
                'Keywords': league[`keywords_${route.name}`] || 'Not set'
            });

            console.log('%cDefault League SEO', 'font-weight: bold; background: #e0e0e0; padding: 2px 5px;');
            console.table({
                'Default Description': league.description || 'Not set',
                'Default Keywords': league.keywords || 'Not set'
            });
        } else {
            console.log('No specific league information available');
        }

        console.log('%cTemplate String Replacements', 'font-weight: bold; background: #e0e0e0; padding: 2px 5px;');
        console.table({
            '%SPORT%': routeSport || 'Not replaced',
            '%LEAGUE%': leagueTitle || 'Not replaced',
            '%LEAGUE_FULL%': (league ? league.title : 'Not replaced'),
            '%LEAGUE_YEAR%': leagueYear || 'Not replaced',
            '%SPORT_OR_LEAGUE%': (routeSport && !routeLeague) ? routeSport : (leagueTitle || 'Not replaced'),
            '%TEAM%': routeTeam || 'Not replaced',
            '%YEAR%': new Date().getFullYear().toString()
        });

        console.groupEnd();
    }

    // Final trim of all fields before returning
    return {
        title: finalTitle.replace(/\s+/g, ' ').trim(),
        description: finalDescription.replace(/\s+/g, ' ').trim(),
        keywords: finalKeywords.replace(/\s+/g, ' ').trim().toLowerCase()
    };
}

// Updated afterEachHook
export default function afterEachHook(to, from, next) {
    Vue.nextTick(() => {
        const sportsData = window.initialState?.sports;
        // const isDebug = process.env.NODE_ENV === 'development' || to.query.seo !== undefined;
        const isDebug = false;
        const seoTags = generateSEOTags(to, sportsData, isDebug);

        // Update document tags
        document.title = seoTags.title;

        // Meta tags update helper
        const updateMetaTag = (name, content) => {
            const selectors = [
                `meta[name="${name}"]`,
                `meta[name="og:${name}"]`,
                `meta[name="twitter:${name}"]`
            ];

            selectors.forEach(selector => {
                const element = document.querySelector(selector);
                if (element) element.setAttribute("content", content);
            });
        };

        // Update all meta tags
        updateMetaTag("title", seoTags.title);
        updateMetaTag("description", seoTags.description);
        updateMetaTag("keywords", seoTags.keywords);
    });
}

// Comment out console.log testing in development
// if (process.env.NODE_ENV === 'development') {
//     testSEOTags(testRoutes, window.initialState?.sports);
// }

// Update test function to compare against expected values
export function testSEOTags(routes, sportsData) {
    const mismatches = [];

    routes.forEach(route => {
        const seoTags = generateSEOTags(route, sportsData, false);
        const expected = route.expected || {};
        const routeIdentifier = `${route.name} ${JSON.stringify(route.params)}`;

        // Check each field and add mismatches
        if (seoTags.title !== expected.title) {
            mismatches.push({
                route: routeIdentifier,
                field: 'title',
                expected: expected.title,
                actual: seoTags.title
            });
        }

        if (seoTags.description !== expected.description) {
            mismatches.push({
                route: routeIdentifier,
                field: 'description',
                expected: expected.description,
                actual: seoTags.description
            });
        }

        if (seoTags.keywords !== expected.keywords) {
            mismatches.push({
                route: routeIdentifier,
                field: 'keywords',
                expected: expected.keywords,
                actual: seoTags.keywords
            });
        }
    });

    if (mismatches.length > 0) {
        console.table(mismatches, ['route', 'field', 'expected', 'actual']);
    } else {
        console.log('All tests passed! No mismatches found.');
    }

    return mismatches;
}
