var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h1',{staticClass:"text-h4 mb-4"},[_vm._v("SEO Test Results")]),_vm._v(" "),_c('v-expansion-panels',_vm._l((_vm.testRoutes),function(route,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_vm._v("\n                        "+_vm._s(route.name)+"\n                        "),_c('code',{staticClass:"ml-2"},[_vm._v(_vm._s(JSON.stringify(route.params)))])]),_vm._v(" "),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.getTestStatus(route).color}},[_vm._v("\n                            "+_vm._s(_vm.getTestStatus(route).icon)+"\n                        ")]),_vm._v("\n                        "+_vm._s(_vm.getTestStatus(route).text)+"\n                    ")],1)],1)],1),_vm._v(" "),_c('v-expansion-panel-content',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',_vm._l(([
                                'title',
                                'description',
                                'keywords',
                            ]),function(field){return _c('div',{key:field},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold mt-3 mb-2"},[_vm._v("\n                                "+_vm._s(field.charAt(0).toUpperCase() +
                                    field.slice(1))+"\n                            ")]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("\n                                        Expected:\n                                    ")]),_vm._v(" "),_c('div',[_vm._v(_vm._s(route.expected[field]))])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("\n                                        Actual:\n                                    ")]),_vm._v(" "),_c('div',{class:{
                                            'red--text':
                                                _vm.getActualValue(
                                                    route,
                                                    field
                                                ) !== route.expected[field],
                                            'green--text':
                                                _vm.getActualValue(
                                                    route,
                                                    field
                                                ) === route.expected[field],
                                        }},[_vm._v("\n                                        "+_vm._s(_vm.getActualValue(route, field))+"\n                                    ")])])],1),_vm._v(" "),(field !== 'keywords')?_c('v-divider',{staticClass:"my-2"}):_vm._e()],1)}),0)],1)],1)],1)}),1),_vm._v(" "),_c('v-card',{staticClass:"mt-4",attrs:{"outlined":""}},[_c('v-card-text',[_c('div',{staticClass:"text-h6 mb-2"},[_vm._v("Summary")]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-stat-card',{attrs:{"color":"green","value":_vm.totalPassing,"title":"Passing","icon":"mdi-check-circle"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"4"}},[_c('v-stat-card',{attrs:{"color":"red","value":_vm.totalFailing,"title":"Failing","icon":"mdi-alert-circle"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"4"}},[_c('v-stat-card',{attrs:{"color":"blue","value":_vm.testRoutes.length,"title":"Total Tests","icon":"mdi-format-list-checks"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }