<template>
    <!-- Player Data Table -->
    <v-data-table
        :headers="playerHeaders"
        :items="sortedPlayers"
        :item-class="rowClass"
        :search="search"
    >
        <template v-slot:top>
            <v-text-field
                prepend-icon="mdi-magnify"
                v-model="search"
                label="Search"
                class="mx-4"
            />
        </template>
        <template v-slot:item.positions="{ item }">
            <span v-for="(position, index) in item.positions" :key="index">
                {{ position.abbreviation }}
            </span>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "NBA",
    props: {
        league: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            search: "",
            playerHeaders: [
                { text: "Last Name", value: "lastName" },
                { text: "First Name", value: "firstName" },
                { text: "Team", value: "team.nickname" },
                { text: "Position", value: "positions[0].abbreviation" },
                { text: "Draft Year", value: "draft.year" },
            ],
        };
    },
    computed: {
        sortedPlayers() {
            return this.league.players
                .slice()
                .sort((a, b) => a.lastName.localeCompare(b.lastName));
        },
    },
    methods: {
        positionsString(positions) {
            return positions.map((p) => p.abbreviation).join(", ");
        },
    },
};
</script>

<style scoped>
.search-field {
    padding-top: 20px;
}

.search-field >>> .v-label {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.search-field >>> input {
    font-size: 20px;
}

.search-field >>> .v-icon {
    font-size: 40px;
}
::v-deep .datatable-row:nth-child(odd) {
    background-color: #f9f9f9 !important;
}
::v-deep .datatable-row:nth-child(even) {
    background-color: #b3d1f2 !important;
}
</style>
